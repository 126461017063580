<template>
  <div>
    <v-row class="mt-5 mb-2">
      <v-col class="d-flex" cols="12" md="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" v-bind="attrs" v-on="on" outlined
              elevation="3">
              <v-card-title>Anniversaires à venir</v-card-title>
              <v-list dense>
                <v-list-item v-for="(birthday, index) in nextBirthday" v-bind:key="index">
                  <v-list-item-avatar size="32">
                    <v-avatar color="primary white--text" size="32">
                      <i style="font-size: 0.8rem" :class="[
                          'fa-solid fa-1x',
                          index === 0 ? 'fa-clock fa-bounce EoleYellow--text' : 'fa-cake-candles white--text',
                        ]"></i>
                    </v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>Anniversaire de {{ birthday.lastname }}
                      {{ birthday.firstname }} ({{
                        birthday.dateFormatted
                      }})</v-list-item-title>
                    <!-- <v-chip style="max-width: 18rem !important"
                      class="text-center align-center justify-center">
                      <v-list-item-subtitle>Cet évènement aura lieu {{ birthday.time }}
                      </v-list-item-subtitle>
                    </v-chip> -->
                  </v-list-item-content>
                  <v-list-item-action v-if="index === 0">
                    <v-list-item-action-text>
                      <v-chip color="EoleYellow" small>A venir !</v-chip>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </template>
          <span>Vous pouvez consulter les 3 prochains anniversaires pour votre entreprise. </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="5">
        <v-card class="flex d-flex flex-column rounded-xl" outlined elevation="3">
          <v-card-title>
            Évènements de l'entreprise

            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" style="">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>

            <v-dialog v-model="dialogAddEvent" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" x-small>
                  <v-icon color="EoleBlue">mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title> Ajouter un nouvel évènement </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field label="Titre de l'évènement" v-model="newEvent.title" dense
                        outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="mt-n5">
                      <v-text-field label="Description" v-model="newEvent.description" dense
                        outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-n5">
                      <v-select v-model="newEvent.idCategory" :value="1" dense outlined
                        :items="eventsCategory" item-text="designation" item-value="id">
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="12" class="mt-n5" v-if="newEvent.idCategory === 1">
                      <v-text-field label="Lien de la réunion" v-model="newEvent.link" dense
                        outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8" class="mt-n5" v-if="newEvent.idCategory != 1">
                      <v-text-field label="Adresse" v-model="newEvent.street" dense outlined>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="mt-n5" v-if="newEvent.idCategory != 1">
                      <v-autocomplete messages="Entrez un code postal"
                        :search-input.sync="searchCity" :items="cityItems" outlined
                        v-model="newEvent.city" :value="newEvent.zip" label="Ville" clearable
                        hide-details hide-selected dense no-filter>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title> Entrez un code postal </v-list-item-title>
                          </v-list-item>
                        </template>

                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{ item }}</v-list-item-title>
                          </v-list-item-content>
                        </template></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12" class="mt-n10">
                      <v-switch inset label="Évènement réccurent" v-model="newEvent.isRecursive">
                      </v-switch>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-n3">
                      <v-text-field v-model="newEvent.dateStart" dense outlined
                        label="Date de début" type="date">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-n3" v-if="!newEvent.isRecursive">
                      <v-text-field v-model="newEvent.dateEnd" dense outlined label="Date de fin"
                        type="date">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-n3">
                      <v-text-field v-model="newEvent.timeStart" dense outlined
                        label="Heure de début" type="time">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-n3">
                      <v-text-field v-model="newEvent.timeEnd" dense outlined label="Heure de fin"
                        type="time">
                      </v-text-field>
                    </v-col>

                  </v-row>

                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialogAddEvent = false">Annuler
                  </v-btn>
                  <v-btn color="primary" text @click="postEvent">Ajouter</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-list dense v-if="events.length > 0" class="mt-n1">
            <v-list-item v-for="(event, index) in events" v-bind:key="index">
              <v-list-item-avatar size="32" v-if="event.id_agency_event_category === 1">
                <v-avatar color="primary white--text" size="32">
                  <v-icon color="white">mdi-microsoft-teams</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-avatar size="32" v-else>
                <v-avatar color="primary white--text" size="32"> <i
                    class="fa-solid fa-comments"></i> </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ event.label }} (De {{ event.time_start }} jusqu'à
                  {{ event.time_end }})
                </v-list-item-title>
                <!-- <v-list-item-subtitle>{{ event.description }}</v-list-item-subtitle> -->

                <v-list-item-subtitle v-if="event.each === 1">
                  <v-icon small color="EoleBlue">mdi-history</v-icon> Cet évènement aura lieu chaque
                  {{ event.information }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="event.street != null || event.city != null || event.zip != null">
                  <v-icon small color="EoleBlue">mdi-map-marker</v-icon>
                  {{ event.street }} {{ event.city }}, {{ event.zip }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-menu right transition="slide-x-transition" close-on-content-click>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="justify-center text-center mr-1">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item target="_blank" :href="event.link" link co
                      v-if="event.id_agency_event_category === 1">
                      <v-list-item-title>
                        <v-icon color="EoleBlue" left>mdi-microsoft-teams</v-icon>
                        Rejoindre la réunion
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-else target="_blank" link
                      :href="`https://www.google.fr/maps/place/${event.street} ${event.city}, ${event.zip}`">
                      <v-list-item-title>
                        <i class="fa-solid fa-location-dot mr-2 EoleBlue--text"
                          style="font-size: 1rem"></i>
                        Comment m'y rendre
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="event.id_agency_event_category != 1" link
                      @click="tab = 'tab-9'; dialogAddCost = true">
                      <v-list-item-title>
                        <i class="fa-solid fa-clipboard mr-2 EoleBlue--text"
                          style="font-size: 1rem"></i>
                        Déclarer une note de frais
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="deleteEvent(event.id)">
                      <v-list-item-title>
                        <i class="fa-solid fa-trash-can mr-2 EoleError--text"
                          style="font-size: 1rem"></i>
                        Supprimer cet évènement
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

              </v-list-item-action>


            </v-list-item>
          </v-list>
          <v-row v-else>
            <v-col cols="12" md="12" align-self="center">
              <v-card-text>
                Aucun évènement n'est publié pour le moment.
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="d-flex" cols="12" md="3">
        <v-card class="flex flex-column rounded-xl" elevation="3" outlined>
          <v-card-title>

            Frais total pour {{ currentMonth }}
          </v-card-title>
          <v-spacer></v-spacer>
          <div class="d-flex flex-column flex-grow-1 mt-10">
            <v-card-text class="text-center flex-grow-1">
              <h2 class="font-weight-bold EoleBlue--text" style="font-size: 2rem">
                {{ costAmountOfTheMonth }}€
              </h2>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs color="EoleYellow" class="text-left" v-model="tab" style="box-shadow: none !important"
      background-color="transparent">
      <v-tab href="#tab-1" @click="recordTab('tab-1')">
        A l'affiche
      </v-tab>
      <v-tab href="#tab-7" @click="recordTab('tab-7')">
        Mon temps de travail
      </v-tab>
      <v-tab href="#tab-9" @click="recordTab('tab-9')">
        MES FRAIS
      </v-tab>
      <v-tab href="#tab-10" @click="recordTab('tab-10')">
        MON ENTRETIEN ANNUEL
      </v-tab>

      <v-tab href="#tab-11" @click="recordTab('tab-11')">
        MES FORMATIONS
      </v-tab>
      <v-tab href="#tab-4" @click="recordTab('tab-4')">
        <v-badge v-if="files.process.length > 0" color="EoleError" :content="files.process.length">
          Procédures
        </v-badge>
        <span v-else>Procédures</span>

      </v-tab>


      <v-tab  v-if="files.display.length > 0" href="#tab-5" @click="recordTab('tab-5')">
        <v-badge color="EoleError" :content="files.display.length">
          Affichage obligatoire
        </v-badge>
      </v-tab>
      <v-tab href="#tab-3" @click="recordTab('tab-5')">
        <v-badge v-if="files.money_accord.length > 0" color="EoleError" :content="files.money_accord.length">
          Intéressement        </v-badge>
      </v-tab>
      <v-tab href="#tab-2" @click="recordTab('tab-2')">
        Avantages sociaux
      </v-tab>
      <v-tab href="#tab-12" @click="recordTab('tab-12')">
        Mes paies
      </v-tab>
      <!-- <v-tab href="#tab-3">
        <v-badge v-if="files.money_accord.length > 0" color="EoleError"
          :content="files.money_accord.length">
          Intéressement
        </v-badge>
        <span v-else>Intéressement</span>
      </v-tab> -->



      <!-- <v-tab href="#tab-6">
        Échanges
      </v-tab> -->

      <!-- <v-tab href="#tab-8">
        CE AGÉA
      </v-tab> -->


    </v-tabs>
    <v-divider class="mb-5"></v-divider>
    <v-tabs-items v-model="tab" style="background-color: transparent !important">
      <v-tab-item :value="'tab-1'">
        <holiday-calendar-views :collaborators="collaborators"></holiday-calendar-views>
      </v-tab-item>
      <v-tab-item :value="'tab-2'">

        <v-row>
          <v-col cols="12" md="12"> Aucun document n'est publié pour le moment. <br /> </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item :value="'tab-12'">
        <pays></pays>
      </v-tab-item>

      <v-tab-item :value="'tab-3'">
        <v-row >

          <v-row class="mb-5">
            <v-col cols="12" md="3" v-for="file in files.money_accord" v-bind:key="file.id"
              class="d-flex col-files">
              <v-card style="width: 100%" max-height="350" class="text-center rounded-xl"
                elevation="3" outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" class="text-right">
                      <v-menu right transition="slide-x-transition" close-on-content-click>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" style=""
                            class="justify-center text-center">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item :href="`https://${file.url}`" target="_blank">
                            <v-list-item-title>
                              <div>
                                <v-icon left>mdi-download</v-icon>Télécharger
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>

                  <v-img v-if="file.extension === 'jpg' || file.extension === 'png'" class=""
                    contain max-height="200" :src="`https://${file.url}`"></v-img>

                  <iframe v-else-if="file.extension === 'pdf'" style="width: 100%; height: 200px"
                    :src="`https://${file.url}`"></iframe>
                  <v-row align="center"
                    v-else-if="file.extension === 'docx' || file.extension === 'doc'"
                    style="height: 225px; max-width: 100% !important">
                    <v-col cols="12" md="12">
                      <i class="fa-regular fa-file-word EoleBlue--text fa-8x"></i>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  {{ file.name }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-col>
        </v-row>
        <!-- <v-row v-else>
          <v-col cols="12" md="12"> Aucun document n'est publié pour le moment. <br /> </v-col>
        </v-row> -->
      </v-tab-item>

      <v-tab-item :value="'tab-4'">
        <v-row>
          <v-col cols="12" md="2" class="">
            <v-card outlined elevation="3" class="text-left">
              <v-navigation-drawer permanent>
                <v-list nav dense>
                  <v-list-item-group color="primary">
                    <v-list-item @click="selectTab('tab-1')">
                      <v-list-item-icon class="align-center">
                        <i
                          :class="['fa-solid', tabProcess === 'tab-1' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>RÉCLAMATIONS</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="selectTab('tab-2')">
                      <v-list-item-icon class="align-center">
                        <i
                          :class="['fa-solid', tabProcess === 'tab-2' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>LCBFT </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="selectTab('tab-3')">
                      <v-list-item-icon class="align-center">
                        <i
                          :class="['fa-solid', tabProcess === 'tab-3' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>PPE</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTab('tab-4')">
                      <v-list-item-icon class="align-center">
                        <i
                          :class="['fa-solid', tabProcess === 'tab-4' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title> DDA</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTab('tab-5')">
                      <v-list-item-icon class="align-center">
                        <i
                          :class="['fa-solid', tabProcess === 'tab-5' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title> RGPD</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTab('tab-6')" disabled>
                      <v-list-item-icon class="align-center">
                        <i
                          :class="['fa-solid', tabProcess === 'tab-6' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title> GDA</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTab('tab-7')" disabled>
                      <v-list-item-icon class="align-center">
                        <i
                          :class="['fa-solid', tabProcess === 'tab-7' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title> NOTES DE SERVICE</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
            </v-card>
          </v-col>
          <v-col cols="12" md="10">
            <v-tabs-items v-model="tabProcess" style="background-color: transparent !important">
              <v-tab-item :value="'tab-1'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-card-title class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important">
                      RÉCLAMATIONS</v-card-title>
                    <v-divider class="mb-9 mt-2"></v-divider>

                    <v-row v-if="files.complaint.length > 0">
                      <v-col cols="12" md="4" v-for="file in files.complaint" v-bind:key="file.id"
                        class="d-flex flex-column col-files">
                        <v-card max-height="400" class="text-center rounded-xl" elevation="3">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="12" class="text-right">
                                <v-menu right transition="slide-x-transition"
                                  close-on-content-click>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" style=""
                                      class="justify-center text-center">
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list>
                                    <v-list-item :href="`https://${file.url}`" target="_blank">
                                      <v-list-item-title>
                                        <div>
                                          <v-icon left>mdi-download</v-icon>Télécharger
                                        </div>
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-text>
                            <v-img v-if="file.extension === 'jpg' || file.extension === 'png'"
                              class="" contain max-height="200" :src="`https://${file.url}`">
                            </v-img>

                            <iframe v-else-if="file.extension === 'pdf'"
                              style="width: 100%; height: 200px" :src="`https://${file.url}`">
                            </iframe>
                          </v-card-text>
                          <v-card-text>
                            {{ file.name }}
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col>Aucun document disponible pour la catégorie réclamation</v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :value="'tab-2'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-card-title class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important">
                      LUTTE CONTRE LE BLANCHIMENT DES CAPITAUX ET LE FINANCEMENT DU TERRORISME
                    </v-card-title>
                    <v-divider class="mb-9 mt-2"></v-divider>

                    <v-row v-if="files.lcbft.length > 0">
                      <v-col cols="12" md="4" v-for="file in files.lcbft" v-bind:key="file.id"
                        class="d-flex flex-column col-files">
                        <v-card max-height="350" class="text-center rounded-xl" elevation="3"
                          outlined>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="12" class="text-right">
                                <v-menu right transition="slide-x-transition"
                                  close-on-content-click>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" style=""
                                      class="justify-center text-center">
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list>
                                    <v-list-item :href="`https://${file.url}`" target="_blank">
                                      <v-list-item-title>
                                        <div>
                                          <v-icon left>mdi-download</v-icon>Télécharger
                                        </div>
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-text>
                            <v-img v-if="file.extension === 'jpg' || file.extension === 'png'"
                              class="" contain max-height="200" :src="`https://${file.url}`">
                            </v-img>

                            <iframe v-else-if="file.extension === 'pdf'"
                              style="width: 100%; height: 200px" :src="`https://${file.url}`">
                            </iframe>
                          </v-card-text>
                          <v-card-text>
                            {{ file.name }}
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col>
                        Aucun document disponible pour la catégorie lutte contre le blanchiment des
                        capitaux et le
                        financement du terrorisme
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-3'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-card-title class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important">
                      PERSONNE POLITIQUEMENT EXPOSÉE</v-card-title>
                    <v-divider class="mb-9 mt-2"></v-divider>

                    <v-row v-if="files.ppe.length > 0">
                      <v-col cols="12" md="4" v-for="file in files.ppe" v-bind:key="file.id"
                        class="d-flex flex-column col-files">
                        <v-card max-height="350" class="text-center rounded-xl" elevation="3"
                          outlined>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="12" class="text-right">
                                <v-menu right transition="slide-x-transition"
                                  close-on-content-click>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" style=""
                                      class="justify-center text-center">
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list>
                                    <v-list-item :href="`https://${file.url}`" target="_blank">
                                      <v-list-item-title>
                                        <div>
                                          <v-icon left>mdi-download</v-icon>Télécharger
                                        </div>
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-text>
                            <v-img v-if="file.extension === 'jpg' || file.extension === 'png'"
                              class="" contain max-height="200" :src="`https://${file.url}`">
                            </v-img>

                            <iframe v-else-if="file.extension === 'pdf'"
                              style="width: 100%; height: 200px" :src="`https://${file.url}`">
                            </iframe>
                          </v-card-text>
                          <v-card-text>
                            {{ file.name }}
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col> Aucun document disponible pour la catégorie personne politiquement
                        exposée </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-4'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-card-title class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important">
                      DIRECTIVES DISTRIBUTION EN ASSURANCES</v-card-title>
                    <v-divider class="mb-9 mt-2"></v-divider>

                    <v-row v-if="files.dda.length > 0">
                      <v-col cols="12" md="4" v-for="file in files.dda" v-bind:key="file.id"
                        class="d-flex flex-column col-files">
                        <v-card max-height="350" class="text-center rounded-xl" elevation="3"
                          outlined>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="12" class="text-right">
                                <v-menu right transition="slide-x-transition"
                                  close-on-content-click>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" style=""
                                      class="justify-center text-center">
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list>
                                    <v-list-item :href="`https://${file.url}`" target="_blank">
                                      <v-list-item-title>
                                        <div>
                                          <v-icon left>mdi-download</v-icon>Télécharger
                                        </div>
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-text>
                            <v-img v-if="file.extension === 'jpg' || file.extension === 'png'"
                              class="" contain max-height="200" :src="`https://${file.url}`">
                            </v-img>

                            <iframe v-else-if="file.extension === 'pdf'"
                              style="width: 100%; height: 200px" :src="`https://${file.url}`">
                            </iframe>
                          </v-card-text>
                          <v-card-text>
                            {{ file.name }}
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col> Aucun document disponible pour la catégorie directives distribution en
                        assurances </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :value="'tab-5'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-card-title class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important">
                      RÉGLEMENT GÉNÉRALE SUR LA PROTECTION DES DONNÉES</v-card-title>
                    <v-divider class="mb-9 mt-2"></v-divider>

                    <v-row v-if="files.rgpd.length > 0" class="mb-5">
                      <v-col cols="12" md="3" v-for="file in files.rgpd" v-bind:key="file.id"
                        class="d-flex flex-column col-files">
                        <v-card max-height="400" class="text-center rounded-xl" elevation="3">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="12" class="text-right">
                                <v-menu right transition="slide-x-transition"
                                  close-on-content-click>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" style=""
                                      class="justify-center text-center">
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list>
                                    <v-list-item :href="`https://${file.url}`" target="_blank">
                                      <v-list-item-title>
                                        <div>
                                          <v-icon left>mdi-download</v-icon>Télécharger
                                        </div>
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-text>
                            <v-img v-if="file.extension === 'jpg' || file.extension === 'png'"
                              class="" contain max-height="200" :src="`https://${file.url}`">
                            </v-img>

                            <iframe v-else-if="file.extension === 'pdf'"
                              style="width: 100%; height: 200px" :src="`https://${file.url}`">
                            </iframe>
                          </v-card-text>
                          <v-card-text>
                            {{ file.name }}
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col>
                        Aucun document disponible pour la catégorie réglement générale sur la
                        protection des données
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-6'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-card-title class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important">
                      GELS DES AVOIRS</v-card-title>
                    <v-divider class="mb-9 mt-2"></v-divider>
                  </v-col>
                </v-row>
                <v-row v-if="files.gda.length > 0">
                  <v-col cols="12" md="4" v-for="file in files.gda" v-bind:key="file.id"
                    class="d-flex flex-column col-files">
                    <v-card max-height="350" class="text-center rounded-xl" elevation="3" outlined>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12" class="text-right">
                            <v-menu right transition="slide-x-transition" close-on-content-click>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" style=""
                                  class="justify-center text-center">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item :href="`https://${file.url}`" target="_blank">
                                  <v-list-item-title>
                                    <div>
                                      <v-icon left>mdi-download</v-icon>Télécharger
                                    </div>
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text>
                        <v-img v-if="file.extension === 'jpg' || file.extension === 'png'" class=""
                          contain max-height="200" :src="`https://${file.url}`"></v-img>

                        <iframe v-else-if="file.extension === 'pdf'"
                          style="width: 100%; height: 200px" :src="`https://${file.url}`">
                        </iframe>
                      </v-card-text>
                      <v-card-text>
                        {{ file.name }}
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col> Aucun document disponible pour la catégorie gels des avoirs </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-7'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-card-title class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important">
                      NOTES DE SERVICE</v-card-title>
                    <v-divider class="mb-9 mt-2"></v-divider>
                  </v-col>
                </v-row>
                <v-row v-if="files.notes">
                  <v-col cols="12" md="4" v-for="file in files.notes" v-bind:key="file.id"
                    class="d-flex flex-column col-files">
                    <v-card max-height="350" class="text-center rounded-xl" elevation="3" outlined>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12" class="text-right">
                            <v-menu right transition="slide-x-transition" close-on-content-click>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" style=""
                                  class="justify-center text-center">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item :href="`https://${file.url}`" target="_blank">
                                  <v-list-item-title>
                                    <div>
                                      <v-icon left>mdi-download</v-icon>Télécharger
                                    </div>
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text>
                        <v-img v-if="file.extension === 'jpg' || file.extension === 'png'" class=""
                          contain max-height="200" :src="`https://${file.url}`"></v-img>

                        <iframe v-else-if="file.extension === 'pdf'"
                          style="width: 100%; height: 200px" :src="`https://${file.url}`">
                        </iframe>
                      </v-card-text>
                      <v-card-text>
                        {{ file.name }}
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item :value="'tab-5'">
        <v-row v-if="files.display.length > 0">
          <v-col cols="12" md="12">
            <v-row v-if="!!files" class="mb-5">
              <v-col cols="12" md="3" v-for="file in files.display" v-bind:key="file.id"
                class="d-flex col-files">
                <v-card style="width: 100%" max-height="350" class="text-center rounded-xl"
                  elevation="3" outlined>
                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="12" md="10" class="text-left align-center" align-self="center">
                        <v-card-subtitle class="align-center" v-if="file.title != null">
                          {{ file.title }} {{ file.city }}</v-card-subtitle>
                      </v-col>
                      <v-col cols="12" md="2" class="text-right">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style=""
                              class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :href="`https://${file.url}`" target="_blank">
                              <v-list-item-title>
                                <div>
                                  <v-icon left>mdi-download</v-icon>Télécharger
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text>

                    <v-img v-if="file.extension === 'jpg' || file.extension === 'png'" class=""
                      contain height="200" :src="`https://${file.url}`"></v-img>

                    <iframe v-else-if="file.extension === 'pdf'" style="width: 100%; height: 190px"
                      :src="`https://${file.url}`"></iframe>
                    <v-row align="center"
                      v-else-if="file.extension === 'docx' || file.extension === 'doc'"
                      style="height: 225px; max-width: 100% !important">
                      <v-col cols="12" md="12">
                        <i class="fa-regular fa-file-word EoleBlue--text fa-8x"></i>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text>
                    {{ file.name }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" md="12"> Aucun document n'est publié pour le moment. <br /> </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item :value="'tab-6'">
        <div v-if="serverUp" class="channel-container">
          <v-row>
            <v-col cols="12" md="3">
              <v-navigation-drawer permanent class="rounded-xl elevation-3 outlined" width="85%">
                <v-list>
                  <v-list-item link>
                    <v-list-item-avatar size="48">
                      <v-avatar color="EoleBlue">
                        <v-img :src="user.avatar"></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> {{ user.lastname }} {{ user.firstname }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ user.agency }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon>mdi-menu-down</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list nav dense>
                  <v-list-item-group v-model="selectedChannel" color="primary">
                    <template v-for="(room, index) in rooms">
                      <v-divider v-if="index > 1" :key="index"></v-divider>

                      <v-list-item :key="room.title" @click="joinRoom(room.name)">
                        <v-list-item-avatar>
                          <v-avatar color="EoleBlue">
                            <v-icon left size="16" color="white">mdi-message</v-icon>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title> {{ room.name }} </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>


            </v-col>
            <v-col cols="12" md="8">
              <v-card-title>Canal de discussion</v-card-title>
              <v-card-subtitle>{{ selectedRoom }}</v-card-subtitle>
              <div class="messages-container" ref="messagesContainer">
                <v-list three-line flat color="transparent">
                  <template v-for="(message, index) in messages" :class="{
                      'message-left': isReceivedMessage(message),
                      'message-right': !isReceivedMessage(message),
                    }">
                    <v-divider :key="index"></v-divider>

                    <v-list-item v-if="isReceivedMessage(message)" :key="message.title">
                      <v-list-item-avatar>
                        <v-img :src="message.avatar"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content class="message-sender">
                        <v-list-item-title> {{ message.senderId }} </v-list-item-title>
                        <v-chip>
                          <v-list-item-subtitle v-html="formatMessageText(message.text)">
                          </v-list-item-subtitle>
                        </v-chip>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text v-text="message.datetime">
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-else :key="message.title">
                      <v-list-item-avatar> </v-list-item-avatar>
                      <v-list-item-content class="message-sender">
                        <v-list-item-title> Moi </v-list-item-title>
                        <v-chip color="EoleBlue" class="white--text">
                          <v-list-item-subtitle class="white--text"
                            v-html="formatMessageText(message.text)"></v-list-item-subtitle>
                        </v-chip>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text v-text="message.datetime">
                        </v-list-item-action-text>
                      </v-list-item-action>

                    </v-list-item>
                  </template>
                </v-list>
              </div>


              <div class="typing-message" v-if="isTyping">
                <i class="fa-regular fa-comment-dots fa-beat-fade"></i>
                {{ typingMessage }}
              </div>
              <v-text-field v-model="newMessage" label="Entrez votre message"
                @keyup.enter="sendMessage" @focus="startTyping" @blur="stopTyping"></v-text-field>
              <v-btn @click="sendMessage" color="primary">Envoyer</v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else>Le serveur est indisponible</div>
      </v-tab-item>
      <v-tab-item :value="'tab-7'">
        <holiday @update="update"></holiday>
      </v-tab-item>
      <v-tab-item :value="'tab-8'">

        <v-row>
          <v-col cols="12" md="12">
            Le module CE AGÉA n'est pas encore actif. Merci de réessayer plus tard<br />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item :value="'tab-9'">
        <v-dialog max-width="1300" v-model="dialogHRInformations">

          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9"
                    flat>
                    <v-card-title>
                      <v-img :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px" max-width="120px" alt="logo" contain></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized">Mes Services
                      Assurance</v-card-title>
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL
                    </v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo"
                        width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Information importante</span>
                    </v-card-title>
                    <v-card-text>
                      Merci de conservez vos originaux de frais (tickets de caisses, justificatifs
                      de frais, factures) et de les remettres une fois par mois dans une enveloppe à
                      votre manager.
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAction" right transition="slide-x-transition"
          close-on-content-click width="1300px">
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9"
                    flat>
                    <v-card-title>
                      <v-img :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px" max-width="120px" alt="logo" contain></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized">Mes Services
                      Assurance</v-card-title>
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL
                    </v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo"
                        width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title class="muller-capitalized EoleBlue--text">
                      Votre note du mois de : {{ selectedCostManager.months }}
                      {{ selectedCostManager.year }}
                    </v-card-title>
                    <v-card-text class="my-5">
                      <v-data-table :headers="headersCost" :items="selectedCostManager.month"
                        hide-default-footer>
                        <template v-slot:[`item.price`]="{ item }"> {{ item.price }}€ </template>
                        <template v-slot:[`item.state`]="{ item }">
                          <v-chip small v-if="item.state === 0" color="EoleYellow">En attente
                          </v-chip>

                          <v-chip small v-else-if="item.state === 1" color="EoleBlue">A approuver
                          </v-chip>
                          <v-chip small v-else-if="item.state === 2" color="EoleError">
                            <v-icon left small>mdi-close-thick</v-icon>Refusé
                          </v-chip>
                          <v-chip small v-else color="EoleGreen" outlined>
                            <v-icon left small>mdi-check</v-icon> Validé
                          </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <!-- <v-btn icon @click="openCost(item)">
                            <v-icon>mdi-eye</v-icon>
                          </v-btn> -->
                          <v-menu right transition="slide-x-transition" close-on-content-click>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" style=""
                                class="justify-center text-center">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item @click="openDialogPutCost(item)">
                                <v-list-item-title>
                                  <div>
                                    <v-icon left>mdi-plus</v-icon>Modifier
                                  </div>
                                </v-list-item-title>
                              </v-list-item>

                              <v-list-item color="EoleError" link @click="deleteCost(item.id)"
                                target="blank">
                                <v-list-item-title>
                                  <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>

                        </template>
                      </v-data-table>
                    </v-card-text>
                    <!--
                      <v-data-table v-else :headers="headersCostTransport" :items="[selectedCostManager]" hide-default-footer>
                        <template v-slot:[`item.price`]="{ item }"> {{ item.price }}€</template>
                        <template v-slot:[`item.vehicleBrand`]="{ item }"> {{ item.data[0].vehicleBrand }}</template>
                        <template v-slot:[`item.vehicleModel`]="{ item }"> {{ item.data[0].vehicleModel }}</template>
                        <template v-slot:[`item.powerTax`]="{ item }"> {{ item.data[0].powerTax }}cv</template>
                        <template v-slot:[`item.scale`]="{ item }"> {{ item.data[0].scale }}€/km</template>
                        <template v-slot:[`item.kilometers`]="{ item }"> {{ item.data[0].kilometers }}km</template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn icon>
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                          <v-btn icon color="EoleError">
                            <v-icon>mdi-close-thick</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card-text>  -->

                    <v-card-text>
                      <v-textarea v-model="selectedCostManager.manager_comment"
                        label="Commentaire manager" readonly outlined dense></v-textarea>
                    </v-card-text>
                    <v-spacer> </v-spacer>
                    <v-card-actions >
                      <v-row class="text-center">
                        <v-col cols="12" md="12">
                          <v-btn class="white--text" color="EoleGreen" :disabled="disabledSendCost"
                        @click="sendCost(selectedCostManager.id_cost_manager)">Envoyer ma note
                      </v-btn>
                        </v-col>
                        <v-col cols="12" md="12">
                          <span v-if="disabledSendCost" class="EoleError--text">
                        {{ errorMsg }}
                        </span>
                        </v-col>
                        </v-row>

                    
                   
                    </v-card-actions>
                  </v-card>
                </v-col>
                <!-- <v-col cols="12" md="3" class="no-padding-top d-flex">
                  <v-card color="transparent" class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title class="muller-capitalized EoleBlue--text">
                      Justificatifs de la note de frais
                    </v-card-title>
                    <v-list dense class="rounded-xl" style="z-index: 0" v-if="selectedCostManager.documents.length > 0">
                      <v-list-item
                        class="rounded-xl"
                        v-for="(document, index) in selectedCostManager.documents"
                        v-bind:key="index"
                        link
                        :href="document.url"
                        target="_blank"
                      >
                        <v-list-item-avatar size="32">
                          <v-avatar color="primary white--text" size="32">
                            <i
                              :class="[
                                'fa-solid ',
                                document.type === 'application/pdf' ? 'fa-file-pdf' : 'fa-file-image',
                                'fa-1x',
                              ]"
                            ></i>
                          </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ document.name }}</v-list-item-title>
                          <v-list-item-subtitle>Importé le : {{ document.date }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text v-text="document.size"></v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-card-text v-else class="text-center">
                      <v-icon size="100" color="EoleBlue">mdi-folder-open-outline</v-icon>
                      <p class="text-h6">Aucun document disponible</p>
                    </v-card-text>
                  </v-card>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPutCost" max-width="1500" @click:outside="newCost = {
        designation: '',
        price: 0,
        idCostCategory: '',
        documents: [],
        idTransport: '',

        }">
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9"
                    flat>
                    <v-card-title>
                      <v-img :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px" max-width="120px" alt="logo" contain></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized">Mes
                      Services Assurance</v-card-title>
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL
                    </v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo"
                        width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Modifier votre frais</span>
                    </v-card-title>
                    <!-- <v-card-subtitle>Veuillez sélectionnez votre type de frais ainsi que son
                      montant</v-card-subtitle>
                      -->
                    <v-card-text>
                      <v-stepper v-model="stepperPutCost" flat>
                        <v-stepper-items>
                          <v-stepper-content step="1" class="no-padding">
                            <v-card-text
                              v-if="newCost.cost_manager_comment != null && newCost.state === 2">
                              <v-alert type="error" color="EoleError" prominent dense text outlined>
                                <strong>Note de votre manager :</strong>
                                {{ newCost.cost_manager_comment }}
                              </v-alert>

                            </v-card-text>
                            <v-card-text>
                              <v-row class="mt-1">
                                <v-col cols="12" md="6">
                                  <v-text-field type="date" outlined dense label="Date de la note"
                                    v-model="newCost.date">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-select label="Type de frais" outlined dense
                                    :items="costsCategory" item-text="designation" item-value="id"
                                    v-model="newCost.idCostCategory"></v-select>
                                </v-col>
                                <v-col cols="12" md="6"
                                  v-if="parseInt(newCost.idCostCategory) === 1">
                                  <v-select label="Type de transport" outlined dense :items="[
                                              {
                                                id: 1,
                                                designation: 'Prime de transport en commun (-50%)',
                                              },
                                              {
                                                id: 2,
                                                designation: 'En commun (totalité)',
                                              },
                                              {
                                                id: 3,
                                                designation: 'Personnel',
                                              },
                                            ]" item-text="designation" item-value="id"
                                    v-model="newCost.idTransport">
                                  </v-select>
                                </v-col>

                                <v-col cols="12" md="6"
                                  v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3">
                                  <v-select label="Motif" outlined dense :items="reasons"
                                    item-text="designation" item-value="id"
                                    v-model="newCost.reason">
                                  </v-select>
                                </v-col>
                                <v-col
                                  v-if="!!newCost.idCostCategory && newCost.idCostCategory != 1 || !!newCost.idTransport && parseInt(newCost.idTransport) === 1 || parseInt(newCost.idTransport) === 2 "
                                  cols="12" md="6">
                                  <v-text-field v-model="newCost.price" label="Montant (en €)"
                                    outlined dense required></v-text-field>
                                </v-col>
                              </v-row>
                              {{newCost}}
                              <v-row
                                v-if="parseInt(newCost.idCostCategory) === 1 && parseInt(newCost.idTransport === 3)">
                                <v-col cols="12" md="6">
                                  <v-autocomplete id="new-account-city"
                                    messages="Entrez un code postal"
                                    :search-input.sync="searchAddressStart"
                                    :value="newCost.address_start" :items="addressItemsStart"
                                    outlined v-model="selectedAddressStart"
                                    label="Adresse de départ" clearable hide-details hide-selected
                                    dense no-filter>
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title> Entrez un code postal
                                        </v-list-item-title>
                                      </v-list-item>
                                    </template>

                                    <template v-slot:item="{ item }">
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6">

                                  <v-autocomplete id="new-account-city"
                                    messages="Entrez un code postal"
                                    :search-input.sync="searchAddressEnd" :items="addressItemsEnd"
                                    outlined v-model="selectedAddressEnd" label="Adresse d'arrivée"
                                    clearable hide-details hide-selected dense no-filter>
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title> Entrez un code postal
                                        </v-list-item-title>
                                      </v-list-item>
                                    </template>

                                    <template v-slot:item="{ item }">
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template></v-autocomplete>
                                </v-col>
                              </v-row>
                              <v-row>

                              </v-row>
                              <v-row
                                v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3">
                                <v-col cols="12" md="6">
                                  <v-text-field v-model="newCost.kilometers"
                                    label="Nombre de kilométres aller-retour" outlined dense
                                    type="number" required>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6"
                                  v-if="newCost.reason === 1 || newCost.reason === 2 || newCost.reason === 3">
                                  <v-text-field v-model="newCost.clientName" label="Nom client"
                                    outlined dense required>
                                  </v-text-field>
                                </v-col>
                              </v-row>

                            </v-card-text>

                          </v-stepper-content>
                          <v-stepper-content step="2" class="no-padding">
                            <v-card-subtitle>Ajoutez vos justificatifs</v-card-subtitle>
                            <v-row class="text-center align-center justify-center mb-5">
                              <v-col cols="12" md="6"
                                v-for="(document, indexDocument) in newCost.documents"
                                v-bind:key="indexDocument">
                                <div>
                                  {{ document.name }} - {{ document.date }}
                                  <span v-if="document === null && document.url === null">Aucun
                                    document n'est
                                    pour le moment disponible.</span>
                                  <iframe v-else id="lddcVisualisator"
                                    style="width: 100%; height: 500px" :src="document.url"></iframe>
                                </div>
                                <v-card-text v-if="document.url != null">
                                  <v-row align="center">
                                    <v-col cols="12" md="6">
                                      <v-btn color="EoleError" class="white--text"
                                        @click="deleteFile(newCost.documents, indexDocument)">
                                        supprimer
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-col>
                              <v-col cols="12" md="6" class="d-flex">
                                <v-card @click="openFileExplorer()" link outlined
                                  class="drop-zone flex d-flex flex-column justify-center text-center">
                                  <v-card-text>
                                    <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                                  </v-card-text>
                                  <v-card-text>Ajout d'un document</v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>


                          </v-stepper-content>
                        </v-stepper-items>
                      </v-stepper>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-btn v-if="stepperPutCost > 1" elevation="0" @click="stepperPutCost--">
                        Précédent</v-btn>

                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="newCost.reason === 1 || newCost.reason === 2 || newCost.reason === 3 ? newCost.clientName === '' && parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3 : null"
                        v-if="stepperAddCost <= 1 " elevation="0" @click="stepperPutCost++">
                        Suivant</v-btn>

                      <v-btn v-if="stepperPutCost === 2" elevation="0" @click="putCost(newCost)"
                        color="EoleGreen" class="white--text">Modifier cette note de frais
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3"
                  cols="12" md="3" class="no-padding-top no-padding-bottom d-flex">

                  <v-card trans color="transparent"
                    class="rounded-r-xl flex-fill flex d-flex flex-column" flat>

                    <v-card-title class="justify-center text-no-wrap">Barème fiscal applicable
                      en {{currentYear}}
                    </v-card-title>

                    <v-card-text>
                      <v-list dense class="rounded-xl" style="z-index: 0">
                        <v-list-item class="rounded-xl" v-for="(rate, index) in ratesScale"
                          :key="index"
                          :style="vehicle.power > 7 && rate.power === 'default' || vehicle.power <= 7 && vehicle.power === rate.power ? 'background-color: rgba(82, 183, 136, 0.2);' : ''"
                          >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ rate.power === 'default' ? '7 Cv et plus' : rate.power + ' Cv' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Tarif par kilomètre : {{ rate.rate }} €
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text>
                              <span v-if="vehicle.power > 7 && rate.power === 'default'" class="EoleGreen--text font-weight-bold">
                                Taux appliqué
                              </span>
                              <span v-else-if="vehicle.power <= 7 && vehicle.power === rate.power" class="EoleGreen--text font-weight-bold">
                                Taux appliqué
                              </span>
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <!-- <v-data-table
    v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3"
                  :headers="headersScale"
                  :items="ratesScale"
                  class="elevation-1"
                  dense
                  item-class="item-class"
                  hide-default-footer
                  full-width
                >
                  <template v-slot:item="{ item }">
                    <tr :style="vehicle.power > 7 && item.power === 'default' ? 'background-color: rgba(82, 183, 136, 0.2);' : vehicle.power <= 7 && vehicle.power === item.power ? 'background-color: rgba(82, 183, 136, 0.2);' : ''">
                      <td>{{ item.power === 'default' ? '7 Cv et plus' : item.power }}</td>
                      <td>{{ item.rate }} €</td>
                      <td>
                        <template v-if="vehicle.power > 7 && item.power === 'default'">
                          Tarif appliqué pour vous : {{ item.rate }} €
                        </template>
                        <template v-else-if="vehicle.power <= 7 && vehicle.power === item.power">
                          Tarif appliqué pour vous : {{ item.rate }} €
                        </template>
                      </td>
                    </tr>
                  </template>
                </v-data-table> -->
                    </v-card-text>
                    <v-card-text
                      v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3 && !!newCost.kilometers"
                      class="mt-5">

                      <v-text-field solo-inverted rounded readonly :value="displayCalculation"
                        label="Calcul basé sur le barème"></v-text-field>


                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions class="no-padding-bottom"
                      v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3 && !!newCost.kilometers">
                      <v-card class="rounded-xl" flat width="100%">
                        <v-card-text>Total : {{result}}€</v-card-text>
                      </v-card>

                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>


        </v-dialog>

        <v-row class="mb-n9 mt-5">
          <v-col cols="12" md="2">
            <v-text-field label="Année(s)" v-model="filter.year" dense>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field label="Mois" v-model="filter.month" dense></v-text-field>
          </v-col>
          <v-col cols="12" md="2">

          </v-col>
          <v-col cols="12" md="6" class="text-right">
            <v-dialog max-width="1300" v-model="dialogCostsHistory">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="dialogVisible = true" color="EoleBlue">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                <v-card-text style="background-color: #f1f4f9">
                  <v-row>
                    <v-col cols="12" md="3" class="no-padding ml-n2">
                      <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9"
                        flat>
                        <v-card-title>
                          <v-img :src="require('@/assets/images/logos/anavel_logo.png')"
                            max-height="150px" max-width="120px" alt="logo" contain></v-img>
                        </v-card-title>
                        <v-card-title class="text-center justify-center muller-capitalized">Mes
                          Services Assurance</v-card-title>
                        <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL
                        </v-card-subtitle>
                        <v-card-text class="no-padding">
                          <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo"
                            width="300px" class="">
                          </v-img>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="9" class="no-padding d-flex">
                      <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                        <v-card-title>
                          <span class="text-h5">Historique des notes de frais</span>
                        </v-card-title>
                        <v-card-text>
                          <v-row class="mb-n10">
                            <v-col cols="12" md="2">
                              <v-text-field label="Année" v-model="yearOrdredHistory">
                              </v-text-field>
                              <!-- <v-select label="Année(s)" multiple :items="dates" v-model="filter.year" dense></v-select> -->
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-text-field label="Mois de l'année" v-model="monthOrdredHistory">
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-text>
                          <v-data-table
                            :items="getCostsOrderedHistoryFilter(monthOrdredHistory, yearOrdredHistory)"
                            item-key="id" :headers="headersCostsOrdered" hide-default-footer>
                            <template v-slot:no-data>
                              Aucune note de frais disponible, veuillez changer les paramètres de
                              filtres pour afficher des
                              notes de frais.
                            </template>
                            <template v-slot:[`item.months`]="{ item }"> {{ item.months }}
                              {{ item.year }} </template>

                            <template v-slot:[`item.monthPrice`]="{ item }">
                              {{ item.monthPrice }}€</template>
                            <template v-slot:[`item.state`]="{ item }">
                              <v-chip small v-if="item.state === 0" color="EoleYellow">En attente
                              </v-chip>

                              <v-chip small v-else-if="item.state === 1" color="EoleBlue">A
                                approuver</v-chip>
                              <v-chip small v-else-if="item.state === 2" color="EoleError">
                                <v-icon left small>mdi-close-thick</v-icon>Refusé
                              </v-chip>
                              <v-chip small v-else color="EoleGreen" outlined>
                                <v-icon left small>mdi-check</v-icon> Validé
                              </v-chip>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-btn icon @click="openDialog(item)">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <!-- <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="postNewCompensatoryLeave(newCompensatoryLeaveBalance)"
                        >Terminer</v-btn
                      >
                    </v-card-actions> -->
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogAddCost" max-width="1500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" fab icon color="EoleBlue">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                <v-card-text style="background-color: #f1f4f9">
                  <v-row>
                    <v-col cols="12" md="3" class="no-padding ml-n2">
                      <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9"
                        flat>
                        <v-card-title>
                          <v-img :src="require('@/assets/images/logos/anavel_logo.png')"
                            max-height="150px" max-width="120px" alt="logo" contain></v-img>
                        </v-card-title>
                        <v-card-title class="text-center justify-center muller-capitalized">Mes
                          Services Assurance</v-card-title>
                        <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL
                        </v-card-subtitle>
                        <v-card-text class="no-padding">
                          <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo"
                            width="300px" class="">
                          </v-img>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12"
                      :md="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3 ? '6' : '9'"
                      class="no-padding d-flex">
                      <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                        <v-card-title>
                          <span class="text-h5">Ajouter un nouveau frais</span>
                        </v-card-title>
                        <v-card-subtitle>Veuillez sélectionnez votre type de frais ainsi que son
                          montant</v-card-subtitle>
                        <v-card-text>
                          <v-stepper v-model="stepperAddCost" flat>
                            <v-stepper-items>
                              <v-stepper-content step="1" class="no-padding">
                                <!-- <v-card-subtitle>Informations</v-card-subtitle> -->
                                <v-card-text>
                                  <v-row class="mt-1">
                                    <v-col cols="12" md="6">
                                      <v-text-field type="date" outlined dense
                                        label="Date de la note" v-model="newCost.date">
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                      <v-select label="Type de frais" outlined dense
                                        :items="costsCategory" item-text="designation"
                                        item-value="id" v-model="newCost.idCostCategory"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6"
                                      v-if="parseInt(newCost.idCostCategory) === 1">
                                      <v-select label="Type de transport" outlined dense :items="[   {
                                                id: 1,
                                                designation: 'Prime de transport en commun (-50%)',
                                              },
                                              {
                                                id: 2,
                                                designation: 'En commun (totalité)',
                                              },
                                              {
                                                id: 3,
                                                designation: 'Personnel',
                                              },
                                      ]" item-text="designation" item-value="id"
                                        v-model="newCost.idTransport">
                                      </v-select>
                                    </v-col>
                                    <v-col cols="12" md="6"
                                      v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3">
                                      <v-select label="Motif" outlined dense :items="reasons"
                                        item-text="designation" item-value="id"
                                        v-model="newCost.reason">
                                      </v-select>
                                    </v-col>
                                    <v-col
                                      v-if="!!newCost.idCostCategory && newCost.idCostCategory != 1 || !!newCost.idTransport && parseInt(newCost.idTransport) === 1 || !!newCost.idTransport && parseInt(newCost.idTransport) === 2"
                                      cols="12" md="6">
                                      <v-text-field v-model="newCost.price" label="Montant (en €)"
                                        outlined dense required></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3"
                                    class="mt-n5">
                                    <v-col cols="12" md="6">
                                      <v-autocomplete id="new-account-city"
                                        messages="Entrez un code postal"
                                        :search-input.sync="searchAddressStart"
                                        :items="addressItemsStart" outlined
                                        v-model="selectedAddressStart" label="Adresse de départ"
                                        clearable hide-details hide-selected dense no-filter>
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title> Entrez un code postal
                                            </v-list-item-title>
                                          </v-list-item>
                                        </template>

                                        <template v-slot:item="{ item }">
                                          <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                          </v-list-item-content>
                                        </template></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6">

                                      <v-autocomplete id="new-account-city"
                                        messages="Entrez un code postal"
                                        :search-input.sync="searchAddressEnd"
                                        :items="addressItemsEnd" outlined
                                        v-model="selectedAddressEnd" label="Adresse d'arrivée"
                                        clearable hide-details hide-selected dense no-filter>
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title> Entrez un code postal
                                            </v-list-item-title>
                                          </v-list-item>
                                        </template>

                                        <template v-slot:item="{ item }">
                                          <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                          </v-list-item-content>
                                        </template></v-autocomplete>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3">
                                    <v-col cols="12" md="6">
                                      <v-text-field v-model="newCost.kilometers"
                                        label="Nombre de kilométres aller-retour" outlined dense
                                        type="number" required>
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6"
                                      v-if="newCost.reason === 1 || newCost.reason === 2 || newCost.reason === 3">
                                      <v-text-field v-model="newCost.clientName" label="Nom client"
                                        outlined dense required>
                                      </v-text-field>
                                    </v-col>
                                  </v-row>

                                </v-card-text>

                              </v-stepper-content>
                              <v-stepper-content step="2" class="no-padding">
                                <v-card-subtitle>Ajoutez vos justificatifs</v-card-subtitle>
                                <v-row class="text-center align-center justify-center mb-5">
                                  <v-col cols="12" md="6"
                                    v-for="(document, indexDocument) in newCost.documents"
                                    v-bind:key="indexDocument">
                                    <div>
                                      {{ document.name }} - {{ document.date }}
                                      <span v-if="document === null && fileUrl === null">Aucun
                                        document n'est
                                        pour le moment disponible.</span>
                                      <iframe v-else id="lddcVisualisator"
                                        style="width: 100%; height: 500px"
                                        :src="filesUrl[indexDocument]"></iframe>
                                    </div>
                                    <v-card-text v-if="document.url != null">
                                      <v-row align="center">
                                        <v-col cols="12" md="6">
                                          <v-btn color="EoleError" class="white--text"
                                            @click="deleteFile(editComplaint.documents, indexDocument)">
                                            supprimer
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-col>
                                  <v-col cols="12" md="6" class="d-flex">
                                    <v-card @click="openFileExplorer()" link outlined
                                      class="drop-zone flex d-flex flex-column justify-center text-center">
                                      <v-card-text>
                                        <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                                      </v-card-text>
                                      <v-card-text>Ajout d'un document</v-card-text>
                                    </v-card>
                                  </v-col>
                                </v-row>


                              </v-stepper-content>
                            </v-stepper-items>
                          </v-stepper>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions>
                          <v-btn v-if="stepperAddCost > 1" elevation="0" @click="stepperAddCost--">
                            Précédent</v-btn>

                          <v-spacer></v-spacer>
                          <v-btn
                            :disabled="newCost.reason === 1 || newCost.reason === 2 || newCost.reason === 3 ? newCost.clientName === '' && parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3 : null"
                            v-if="stepperAddCost <= 1 " elevation="0" @click="stepperAddCost++">
                            Suivant</v-btn>

                          <v-btn v-if="stepperAddCost === 2" elevation="0" @click="postCost"
                            color="EoleGreen" class="white--text">Ajouter cette note de frais
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                    <v-slide-x-transition>
                      <v-col
                        v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3"
                        cols="12" md="3" class="no-padding-top no-padding-bottom d-flex">

                        <v-card trans color="transparent"
                          class="rounded-r-xl flex-fill flex d-flex flex-column" flat>

                          <v-card-title class="justify-center text-no-wrap">Barème fiscal applicable
                            en {{currentYear}}
                          </v-card-title>

                          <v-card-text>
                            <v-list dense class="rounded-xl" style="z-index: 0">
                              <v-list-item class="rounded-xl" v-for="(rate, index) in ratesScale"
                                :key="index"
                                :style="vehicle.power > 7 && rate.power === 'default' || vehicle.power <= 7 && vehicle.power === rate.power ? 'background-color: rgba(82, 183, 136, 0.2);' : ''"
                                >
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ rate.power === 'default' ? '7 Cv et plus' : rate.power + ' Cv' }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>Tarif par kilomètre : {{ rate.rate }} €
                                  </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-list-item-action-text>
                                    <span v-if="vehicle.power > 7 && rate.power === 'default'" class="EoleGreen--text font-weight-bold">
                                Taux appliqué
                              </span>
                              <span v-else-if="vehicle.power <= 7 && vehicle.power === rate.power" class="EoleGreen--text font-weight-bold">
                                Taux appliqué
                              </span>
                                  </v-list-item-action-text>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                            <!-- <v-data-table
                          v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3"
                                        :headers="headersScale"
                                        :items="ratesScale"
                                        class="elevation-1"
                                        dense
                                        item-class="item-class"
                                        hide-default-footer
                                        full-width
                                      >
                                        <template v-slot:item="{ item }">
                                          <tr :style="vehicle.power > 7 && item.power === 'default' ? 'background-color: rgba(82, 183, 136, 0.2);' : vehicle.power <= 7 && vehicle.power === item.power ? 'background-color: rgba(82, 183, 136, 0.2);' : ''">
                                            <td>{{ item.power === 'default' ? '7 Cv et plus' : item.power }}</td>
                                            <td>{{ item.rate }} €</td>
                                            <td>
                                              <template v-if="vehicle.power > 7 && item.power === 'default'">
                                                Tarif appliqué pour vous : {{ item.rate }} €
                                              </template>
                                              <template v-else-if="vehicle.power <= 7 && vehicle.power === item.power">
                                                Tarif appliqué pour vous : {{ item.rate }} €
                                              </template>
                                            </td>
                                          </tr>
                                        </template>
                                      </v-data-table> -->
                          </v-card-text>
                          <v-card-text
                            v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3 && !!newCost.kilometers"
                            class="mt-5">

                            <v-text-field solo-inverted rounded readonly :value="displayCalculation"
                              label="Calcul basé sur le barème"></v-text-field>


                          </v-card-text>
                          <v-spacer></v-spacer>
                          <v-card-actions class="no-padding-bottom"
                            v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 3 && !!newCost.kilometers">
                            <v-card class="rounded-xl" flat width="100%">
                              <v-card-text>Total : {{result}}€</v-card-text>
                            </v-card>

                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-slide-x-transition>
                  </v-row>
                </v-card-text>
              </v-card>


            </v-dialog>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-col cols="12" md="12" class="d-flex flex-column flex">
            <v-data-table :headers="headersCostsOrdered" :items="
              getCostsOrderedFilter(filter.month, filter.year)" item-key="id"
              class="elevation-1 header-table" :items-per-page="-1" disable-pagination
              hide-default-footer>
              <template v-slot:no-data>
                Aucune note de frais disponible, veuillez changer les paramètres de filtres pour
                afficher des notes de
                frais.
              </template>
              <template v-slot:[`item.months`]="{ item }"> {{ item.months }} {{ item.year }}
              </template>

              <template v-slot:[`item.monthPrice`]="{ item }"> {{ item.monthPrice }}€</template>
              <template v-slot:[`item.state`]="{ item }">
                <v-chip small v-if="item.state === 0" color="EoleYellow">En attente</v-chip>

                <v-chip small v-else-if="item.state === 1" color="EoleBlue">A approuver</v-chip>
                <v-chip small v-else-if="item.state === 2" color="EoleError">
                  <v-icon left small>mdi-close-thick</v-icon>Refusé
                </v-chip>
                <v-chip small v-else color="EoleGreen" outlined>
                  <v-icon left small>mdi-check</v-icon> Validé
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click="openDialog(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <!-- <v-menu right transition="slide-x-transition" close-on-content-click>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" style=""
                      class="justify-center text-center">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="openDialogPutCost(item)">
                      <v-list-item-title>
                        <div>
                          <v-icon left>mdi-plus</v-icon>Modifier
                        </div>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item color="EoleError" link @click="deleteCost(item.id)" target="blank">
                      <v-list-item-title>
                        <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
              </template>


            </v-data-table>
          </v-col>

        </v-row>
      </v-tab-item>

      <v-tab-item :value="'tab-10'">
        <v-row>
          <v-col>
            <v-btn @click="putMaintenance">Sauvegarder</v-btn>
          </v-col>
        </v-row>
        <!-- <v-timeline v-for="(maintenance, index) in user.maintenance" v-bind:key="index">
          <v-timeline-item color="red lighten-2" right>
            <template v-slot:icon>
              <v-avatar>
                <img :src="
                                require(`@/assets/images/avatars/picture_profil/${user.services.map(service =>
                                  service.manager.map(manager => `${manager.firstname_manager.toLowerCase()}`),
                                )}.png`)
                              " />
              </v-avatar>
            </template>
            <v-card outlined class="rounded-xl" elevation="3">
              <v-card-title class="text-h5">
                <v-text-field outlined v-model="maintenance.question" dense readonly></v-text-field>
              </v-card-title>
              <v-card-text>
                La réponse de votre manager n'est pas visible à ce stade.
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="red lighten-2" left>
            <template v-slot:icon>
              <v-avatar>
                <img :src="avatarUrl" />
              </v-avatar>
            </template>
            <v-card outlined class="rounded-xl" elevation="3">
              <v-card-title class="text-h5"> Votre réponse </v-card-title>
              <v-card-text>
                <v-textarea outlined dense v-model="maintenance.answer_collaborator"></v-textarea>
              </v-card-text>
            </v-card>
          </v-timeline-item>

        </v-timeline> -->
      </v-tab-item>

      <v-tab-item :value="'tab-11'">

        <v-dialog v-model="dialogUploadDocument" max-width="800px">
              <v-card flat>
                <v-card-title>
                  <span class="text-h5">Import d'un fichier</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-5">
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-card flat class="text-center align-center justify-center">
                        <v-row class="pa-5">
                          <v-col cols="12" md="12">
                            <div>
                              <span
                                v-if="documentTraining === null && fileUrlTraining === null">Aucun
                                document n'est pour le moment fileUrlTraining.</span>
                              <v-img v-else-if="
                                                    (documentTraining != null &&
                                                      documentTraining.data != null &&
                                                      documentTraining.data.type === 'image/jpeg') ||
                                                    documentTraining.data.type === 'image/png'
                                                  " contain class="mx-auto" :src="fileUrlTraining">
                              </v-img>
                              <iframe v-else-if="
                                                    documentTraining != null &&
                                                    documentTraining.data != null &&
                                                    documentTraining.data.type === 'application/pdf'
                                                  " id="lddcVisualisator"
                                style="width: 100%; height: 500px" :src="fileUrlTraining"></iframe>

                              <v-row align="center" v-else-if="
                                                    documentTraining != null &&
                                                    documentTraining.data != null &&
                                                    documentTraining.data.type ===
                                                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                  " style="height: 225px">
                                <v-col cols="12" md="12">
                                  <i class="fa-regular fa-file-word EoleBlue--text fa-8x"></i>
                                </v-col>
                              </v-row>
                            </div>

                            <v-card-text v-if="documentTraining.url != null">
                              <v-row align="center"> </v-row>
                            </v-card-text>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-btn color="EoleYellow" text @click="
                                        () => {
                                          dialogUploadDocument = !dialogUploadDocument
                                          documentTraining = {
                                            name: '',
                                            data: {
                                              name: null,
                                              type: null,
                                            },
                                          }
                                        }
                                      ">
                    Annuler
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="EoleGreen" class="white--text" @click="
                                        postTrainingDocument(
                                          selectedTraining.id,
                                        )
                                      ">Ajouter un nouveau document
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


        <v-row class="pa-6 mb-5">
          <v-col cols="12" md="4">
            <v-btn fab icon @click="previousYear">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" class="text-center">
            <span>Les formations pour l'année <strong> {{ year }} </strong> qui
              vous sont attribuées</span>
          </v-col>
          <v-col cols="12" md="4" class="text-right">
            <v-btn :disabled="year >= currentYear" fab icon @click="nextYear">
              <v-icon>mdi-chevron-right </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table v-if="user.allTrainings.length > 0" class="no-padding" item-key="id"
          :headers="headersTrainingsItems" :items="getTrainingByYear(user.allTrainings)"
          hide-default-footer>
          <template v-slot:no-data>
            Aucune formation n'a été suivi en {{ year }} pour le moment</template>
          <template v-slot:[`item.time`]="{ item }"> {{ item.hour }}:{{ item.minute }}
          </template>
          <template v-slot:[`item.dda`]="{ item }">
            <v-icon v-if="item.isDDA" color="EoleGreen">mdi-check</v-icon>
            <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.designation }}
          </template>
          <template v-slot:[`item.documents`]="{ item }">

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="item.training_module_url != null && item.training_module_url != ''"
                  v-on="on" v-bind="attrs" @click="openTrainingDocuments(item, 1)" icon
                  color="EoleError" target="_blank">
                  <i class="fa-solid fa-file-pdf icon-medium"></i>
                </v-btn>
                <v-btn v-else v-on="on" v-bind="attrs" icon @click="uploadNewTraining(item, 1)">
                  <i class="fa-solid fa-file-import icon-medium"></i>
                </v-btn>
              </template>
              <span
                v-if="item.training_module_url != null && item.training_module_url != ''">Télécharger
                le module de formation</span>
              <span v-else>Importer le module de formation</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.training_attestation_url != null && item.training_attestation_url != ''"
                  v-on="on" v-bind="attrs" @click="openTrainingDocuments(item, 2)" icon
                  color="EoleError" target="_blank">
                  <i class="fa-solid fa-file-pdf icon-medium"></i>
                </v-btn>
                <v-btn v-else v-on="on" v-bind="attrs" icon @click="uploadNewTraining(item, 2)">
                  <i class="fa-solid fa-file-import icon-medium"></i>
                </v-btn>
              </template>
              <span
                v-if="item.training_attestation_url != null && item.training_attestation_url != ''">Télécharger
                l'attestation de formation</span>
              <span v-else>Importer l'attestation de formation</span>
            </v-tooltip>
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="item.training_module_url != null && item.training_module_url != ''"
                  v-on="on" v-bind="attrs" :href="item.training_module_url" icon color="EoleError"
                  target="_blank">
                  <i class="fa-solid fa-file-pdf icon-medium"></i>
                </v-btn>
                <v-btn v-else v-on="on" v-bind="attrs" icon @click="uploadNewTraining(item.id, 1)">
                  <i class="fa-solid fa-file-import icon-medium"></i>
                </v-btn>
              </template>
              <span
                v-if="item.training_module_url != null && item.training_module_url != ''">Télécharger
                le module de formation</span>
              <span v-else>Importer le module de formation</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.training_attestation_url != null && item.training_attestation_url != ''"
                  v-on="on" v-bind="attrs" :href="item.training_attestation_url" icon
                  color="EoleError" target="_blank">
                  <i class="fa-solid fa-file-pdf icon-medium"></i> </v-btn>
                <v-btn v-else v-on="on" v-bind="attrs" icon @click="uploadNewTraining(item.id, 2)">
                  <i class="fa-solid fa-file-import icon-medium"></i>
                </v-btn>
              </template>
              <span
                v-if="item.training_attestation_url != null && item.training_attestation_url != ''">Télécharger
                l'attestation de formation</span>
              <span v-else>Importer l'attestation de formation</span>
            </v-tooltip> -->
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu right transition="slide-x-transition" close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>

                <v-list-item color="EoleGreen" link target="blank">
                  <v-list-item-title>
                    <v-icon color="EoleGreen" left>mdi-check-circle</v-icon>Valider la
                    formation
                  </v-list-item-title>
                </v-list-item>


              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <v-dialog v-model="dialogTrainingDocuments" max-width="600">
          <v-card>
            <v-card-title class="text-h5">Visualisation du document</v-card-title>
            <v-card-text>
              <iframe v-if="selectedTraining.id_document === 1" :src="previewedUrl" frameborder="0"
                width="100%" height="600px"></iframe>
              <iframe v-if="selectedTraining.id_document === 2" :src="previewedUrl" frameborder="0"
                width="100%" height="600px"></iframe>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="selectedTraining.id_document === 1" elevation="0"
                @click="uploadNewTraining(selectedTraining, 1)">Modifier mon document</v-btn>
              <v-btn v-if="selectedTraining.id_document === 2" elevation="0"
                @click="uploadNewTraining(selectedTraining, 2)">Modifier mon document</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-data-table item-key="year"
          :loading-text="`Chargement des pièces en cours...\n Merci de patienter`"
          :headers="headersTrainingsItems" :items="getTrainingByYear(user.allTrainings)" hide-default-footer
          show-expand>
          <template v-slot:no-data>
            <v-alert color="error" text class="ma-2">
              <div class="d-flex align-start">
                <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                  </p>
                </div>
              </div>
            </v-alert>
            <v-btn color="primary" class="mb-2">Réessayer</v-btn>
          </template>
          <template v-slot:[`item.sum_year`]="{ item }">
            <v-chip small v-if="item.sum_year != '00h00'"> {{ item.sum_year }} </v-chip>
            <v-chip color="EoleError" small outlined v-else> Aucune heure DDA réalisée </v-chip>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <v-dialog v-model="dialogUploadDocument" max-width="800px">
              <v-card flat>
                <v-card-title>
                  <span class="text-h5">Import d'un fichier</span>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text class="pa-5">
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-card flat class="text-center align-center justify-center">
                        <v-row class="pa-5">

                          <v-col cols="12" md="12">
                            <div>
                              <span
                                v-if="documentTraining === null && fileUrlTraining === null">Aucun
                                document n'est pour le moment fileUrlTraining.</span>
                              <v-img v-else-if="
                                      (documentTraining != null &&
                                        documentTraining.data != null &&
                                        documentTraining.data.type === 'image/jpeg') ||
                                      documentTraining.data.type === 'image/png'
                                    " contain class="mx-auto" :src="fileUrlTraining"></v-img>
                              <iframe v-else-if="
                                      documentTraining != null &&
                                      documentTraining.data != null &&
                                      documentTraining.data.type === 'application/pdf'
                                    " id="lddcVisualisator" style="width: 100%; height: 500px"
                                :src="fileUrlTraining"></iframe>

                              <v-row align="center" v-else-if="
                                      documentTraining != null &&
                                      documentTraining.data != null &&
                                      documentTraining.data.type ===
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                    " style="height: 225px">
                                <v-col cols="12" md="12">
                                  <i class="fa-regular fa-file-word EoleBlue--text fa-8x"></i>
                                </v-col>
                              </v-row>
                            </div>

                            <v-card-text v-if="documentTraining.url != null">
                              <v-row align="center"> </v-row>
                            </v-card-text>
                          </v-col>

                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-btn color="EoleYellow" text @click="
                      () => {
                        dialogUploadDocument = !dialogUploadDocument
                        documentTraining = {
                          name: '',
                          data: {
                            name: null,
                            type: null,
                          },
                        }
                      }
                    ">
                    Annuler
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="EoleGreen" class="white--text" @click="
                      postTrainingDocument()
                    ">Ajouter un nouveau document
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <td :colspan="headers.length" class="no-padding">
              <v-data-table class="no-padding" item-key="id" :headers="headersTrainingsItemsFiles"
                :items="item.training" hide-default-footer hide-default-header>
                <template v-slot:no-data>
                  Aucune formation n'a été suivi en {{ item.year }} pour le moment</template>
                <template v-slot:[`item.time`]="{ item }"> {{ item.hour }}:{{ item.minute }}
                </template>
                <template v-slot:[`item.dda`]="{ item }">
                  <v-icon v-if="item.isDDA" color="EoleGreen">mdi-check</v-icon>
                  <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  {{ item.designation }}
                </template>
                <template v-slot:[`item.documents`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.training_module_url != null && item.training_module_url != ''"
                        v-on="on" v-bind="attrs" :href="item.training_module_url" icon
                        color="EoleError" target="_blank">
                        <i class="fa-solid fa-file-pdf icon-medium"></i>
                      </v-btn>
                      <v-btn v-else v-on="on" v-bind="attrs" icon
                        @click="uploadNewTraining(item.id, 1)">
                        <i class="fa-solid fa-file-import icon-medium"></i>
                      </v-btn>
                    </template>
                    <span
                      v-if="item.training_module_url != null && item.training_module_url != ''">Télécharger
                      le module de formation</span>
                    <span v-else>Importer le module de formation</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.training_attestation_url != null && item.training_attestation_url != ''"
                        v-on="on" v-bind="attrs" :href="item.training_attestation_url" icon
                        color="EoleError" target="_blank">
                        <i class="fa-solid fa-file-pdf icon-medium"></i> </v-btn>
                      <v-btn v-else v-on="on" v-bind="attrs" icon
                        @click="uploadNewTraining(item.id, 2)">
                        <i class="fa-solid fa-file-import icon-medium"></i>
                      </v-btn>
                    </template>
                    <span
                      v-if="item.training_attestation_url != null && item.training_attestation_url != ''">Télécharger
                      l'attestation de formation</span>
                    <span v-else>Importer l'attestation de formation</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu right transition="slide-x-transition" close-on-content-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" style=""
                        class="justify-center text-center">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
           
                      <v-list-item color="EoleGreen" link target="blank">
                        <v-list-item-title>
                          <v-icon color="EoleGreen" left>mdi-check-circle</v-icon>Valider la
                          formation
                        </v-list-item-title>
                      </v-list-item>


                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </td>
          </template>

          <template v-slot:[`item.description`]="{ item }">
            <v-textarea dense v-model="item.description" outlined rows="1" class="mt-5">
            </v-textarea>
          </template>
        </v-data-table> -->


      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import config from "@/views/config/config"
  import io from "socket.io-client"
  import moment from "moment"

  import Holiday from "../../holiday/components/HolidayViews.vue"
  import HolidayCalendarViews from "../../holiday/components/HolidayCalendarViews.vue"
  import Pays from "./components/Pays.vue"
  import emailjs from "@emailjs/browser"
  import {
    init
  } from "@emailjs/browser"
  import {
    getCookie
  } from '@/utils/cookies'
  init("user_gUunL4KW2ImPaGngWHYhP")



  // import VueGoogleMaps from 'vue2-google-maps'

  // Vue.use(VueGoogleMaps, {
  //   load: {
  //     key: 'AIzaSyA4MciI_sdb-GzZMeVK7UvjDl8qeMGKpQU',
  //     libraries: 'places',
  //   },
  // })


  export default {
    name: "serviceTable",
    data: () => ({
      errorMsg: '',
      disabledSendCost: false,
      editedUploadTraining: {
        lastname: "",
        firstname: "",
      },
      selectedTraining: {
        id_document: null,

      },
      documentTraining: {
        name: '',
        data: {
          name: null,
          type: null,
        },
      },
      dialogUploadDocument: false,
      dialogTrainingDocuments: null,
      previewedUrl: null,
      uploadedDocumentUrls: [],
      reasons: [{
          id: 1,
          designation: 'Rdv client',
        },
        {
          id: 2,
          designation: 'Expertise',
        },
        {
          id: 3,
          designation: 'Réunion',
        },
        {
          id: 4,
          designation: 'Formation',
        },
      ],
      currentYear: moment().format("YYYY"),
      headersScale: [{
          text: 'Puissance du véhicule (CV)',
          align: 'start',
          value: 'power'
        },
        {
          text: 'Tarif par kilomètre (€)',
          value: 'rate'
        },
        {
          text: 'Tarif par kilomètre (€)',
          value: 'applicated'
        }
      ],
      ratesScale: [

      ],
      vehicle: null,
      service: {},
      searchAddressStart: null,
      searchAddressEnd: null,
      addressItemsStart: [],
      addressItemsEnd: [],
      selectedAddressStart: {},
      selectedAddressEnd: {},
      addressOptions: [],
      dialogHRInformations: false,
      dialogCostsHistory: false,
      headersCost: [{
          text: "Date de la note de frais",
          align: "start",
          sortable: true,
          value: "date_cost_display",
        },
        {
          text: "Catégorie de frais",
          align: "start",
          sortable: true,
          value: "designation",
        },
        {
          text: "Montant (en €)",
          value: "price",
        },
        {
          text: "État",
          value: "state",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      dialogAction: false,
      selectedCostManager: {},
      monthOrdredHistory: "",
      yearOrdredHistory: "",
      costAmountOfTheMonth: 0,
      costsOrdered: [],
      costsOrderedHistory: [],
      headersCostsOrdered: [{
          text: "Mois de la note de frais",
          align: "start",
          sortable: true,
          value: "months",
        },

        // {
        //   text: "Date de la note de frais",
        //   align: "start",
        //   sortable: true,
        //   value: "date_cost_display",
        // },
        {
          text: "Montant (en €)",
          value: "monthPrice",
        },
        {
          text: "État",
          value: "state",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      dialogPutCost: false,
      stepperPutCost: 1,
      agency: localStorage.getItem("agency"),
      currentYear: moment().format("YYYY"),
      year: moment().format("YYYY"),
      filter: {
        year: "",
        month: "",
      },
      states: [{
          id: 1,
          designation: "A approuver",
        },
        {
          id: 2,
          designation: "Refusé",
        },
        {
          id: 3,
          designation: "Validé",
        },
      ],
      dates: [],
      months: [],
      years: [],
      currentMonth: moment().format("MMMM YYYY"),
      collaborators: [],
      headersTrainingsItems: [
        // {
        //   text: "Année de formation",
        //   value: "year",
        //   sortable: true,
        // },
        {
          text: "Organisme",
          value: "organism",
          sortable: false,
        },
        {
          text: "Titre de la formation",
          align: "start",
          sortable: true,
          value: "designation",
        },
        {
          text: "Date",
          value: "display_date",
          sortable: false,
        },
        {
          text: "Durée",
          value: "display_duration",
          sortable: false,
        },
        // {
        //   text: "Éligible DDA",
        //   value: "dda",
        //   sortable: false,
        // },
        {
          text: "Documents",
          value: "documents",
          sortable: false,
        },

      ],
      headersTrainingsItemsFiles: [{
          text: "Organisme",
          value: "organism",
          sortable: false,
        },
        {
          text: "Titre de la formation",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Date",
          value: "display_date",
          sortable: false,
        },
        {
          text: "Durée",
          value: "display_duration",
          sortable: false,
        },
        {
          text: "Éligible DDA",
          value: "dda",
          sortable: false,
        },
        {
          text: "Documents",
          value: "documents",
          sortable: false,
        },
        // {
        //   text: "Actions",
        //   value: "actions",
        //   sortable: false,
        // },
      ],
      avatarUrl: "",
      dialogAddEvent: false,
      newEvent: {

      },
      tabProcess: "tab-1",
      expanded: [],
      dialogAddCost: false,
      document: {
        name: null,
        data: null,
        categorie: null,
      },
      filesUrl: [],
      stepperAddCost: 1,
      costsCategory: [],
      newCost: {
        designation: "",
        clientName: "",
        price: 0,
        idCostCategory: "",
        documents: [],
        data: null,
        reason: 1,
      },
      eventsCategory: [],
      tab: "tab-1",
      files: {
        rgpd: [],
        display: [],
        agency: [],
        process: [],
        money_accord: [],
        complaint: [],
        lcbft: [],
        ppe: [],
        dda: [],
        gda: [],
      },
      nextBirthday: [],
      isTabFlashing: false,
      selectedChannel: 0,
      selectedRoom: "Général", // Par défaut, tout le monde se connecte à "Général"
      rooms: [{
          name: "Général",
          children: [],
          users: [],
        },
        {
          name: "Particulier",
          children: [],
          users: [],
        },
        {
          name: "Pro",
          children: [],
          users: [],
        },
      ],
      leaves: [],
      searchCity: "",
      serverUp: true,
      messages: [],
      newMessage: "",
      receiverId: "",
      isTyping: false,
      typingMessage: "",
      cityItems: [],
      user: {
        // id: 0,
        // firstname: "",
        // lastname: "",
        // email: "",
        // phone: "",
        // avatar: "",
        // role: "",
        // services: [],
        // maintenance: [],
        allTrainings: [],
      },
      headersCosts: [{
          text: "Date d'enregistrement",
          align: "start",
          sortable: true,
          value: "date_display",
        }, {
          text: "Date de la note de frais",
          align: "start",
          sortable: true,
          value: "date_cost_display",
        },

        {
          text: "Catégorie de frais",
          align: "start",
          sortable: true,
          value: "designation",
        },
        {
          text: "Montant (en €)",
          value: "price",
        },
        {
          text: "État",
          value: "state",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      events: [],
      documentTraining: {
        name: null,
        data: {
          name: null,
          type: null,
        },
        categorie: null,
      },
      dialogUploadDocument: false,
      fileUrlTraining: null,

    }),

    async created() {

      //create copy of the user

      // this.user = {...this.$store.state.user}
      console.log(this.user)
      const agency = localStorage.getItem("agency")

      //   this.user.avatar =
      //     `${
      //   config.ged
      // }/${agency.toLowerCase()}/users/${this.user.lastname.toLowerCase()}-${this.user.firstname.toLowerCase()}/profil.png`

      this.fetchFiles().finally(() => {})
      this.fetchNextBirthday()
      this.fetchCostByUserId()
      this.fetchEvents()
      // this.fetchMaintenanceByUserId()
      this.fetchAccountTraining()
      this.fetchAgencyHoliday()
      this.generateMonth()
      this.generateYears()
      this.fetchVehicleInformations()
      this.fetchRatesScale()

    },
    beforeDestroy() {
      // Envoyer un message de déconnexion au serveur
      const userName = localStorage.getItem("complete_name")
      this.socket.emit("userDisconnected", userName)
      this.socket.disconnect()
      console.log("ok")
    },
    computed: {
      displayCalculation() {


        return `${this.newCost.kilometers} x ${this.getScale()} = ${this.result}`;
      },
      result() {
        const rate = this.ratesScale.find(item => item.power === this.vehicle.power || item
          .power === 'default');
        return rate ? parseFloat(parseInt(this.newCost.kilometers) * rate.rate).toFixed(2) : '0.00';
        // switch (this.vehicle.power) {
        //   case 1:
        //   case 2:
        //   case 3:
        //     return parseFloat(parseInt(this.newCost.kilometers) * 0.529).toFixed(2)
        //   case 4:
        //     return parseFloat(parseInt(this.newCost.kilometers) * 0.606).toFixed(2)
        //   case 5:
        //     return parseFloat(parseInt(this.newCost.kilometers) * 0.636).toFixed(2)
        //   case 6:
        //     return parseFloat(parseInt(this.newCost.kilometers) * 0.665).toFixed(2)
        //   default:
        //     return parseFloat(parseInt(this.newCost.kilometers) * 0.697).toFixed(2)

        // }
      },
      filteredAddresses() {
        return this.addressOptions.filter((address) =>
          address.properties.label.toLowerCase().includes(this.selectedAddress.toLowerCase())
        );
      },
    },
    watch: {
      searchAddressStart(query) {

        try {
          fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&limit=5`)
            .then(res => res.clone().json())
            .then(data => {
              if (data.features && data.features.length > 0) {
                // Récupérer les résultats de la recherche
                const results = data.features;

                // Traitement des résultats ici
                this.addressItemsStart = results.map(item => item.properties.label);
              } else {
                // Aucun résultat trouvé
                console.log('Aucun résultat trouvé');
              }
            })
            .catch(err => {
              console.error(err);
            });

        } catch (e) {}
      },
      searchAddressEnd(query) {

        try {
          fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&limit=5`)
            .then(res => res.clone().json())
            .then(data => {
              if (data.features && data.features.length > 0) {
                // Récupérer les résultats de la recherche
                const results = data.features;
                // Traitement des résultats ici
                this.addressItemsEnd = results.map(item => item.properties.label);
              } else {
                // Aucun résultat trouvé
                console.log('Aucun résultat trouvé');
              }
            })
            .catch(err => {
              console.error(err);
            });

        } catch (e) {}
      },
      searchCity(val) {
        console.log(val)
        //verify if is number
        if (/^\d{5}$/.test(val)) {
          try {
            fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
              .then(res => res.clone().json())
              .then(res => {
                this.cityItems = res.map(item => item.nom)
                this.newEvent.zip = val
              })
              .catch(err => {
                console.log(err)
              })


          } catch (e) {

          }
        } else {

        }
      },
    },
    mounted() {
      // this.$nextTick(() => {
      //   this.$refs.mapRef.mapObject.invalidateSize();
      // });
      if (this.$route.query.menu) {
        this.tab = this.$route.query.menu
      }

   
    },
    components: {
      Holiday,
      HolidayCalendarViews,
      Pays,
    },
    methods: {
      uploadNewTraining(training, id) {
        this.selectedTraining = training
        console.log(training)
        try {
          const input = document.createElement("input")
          input.type = "file"
          input.accept =
            "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          input.addEventListener("change", event => {
            this.documentTraining.idTraining = training.id
            this.documentTraining.name = event.target.files[0].name
            this.documentTraining.type = id
            this.documentTraining.data = event.target.files[0]
            this.fileUrlTraining = URL.createObjectURL(this.documentTraining.data)
            this.dialogUploadDocument = true
            // this.uploadFile(event, type, area, subType)
          })
          input.click()
        } catch (err) {
          console.log(err)
        }
      },
      async previewFile(path) {
        const idAccount = this.$store.state.user.id
        let url
        try {
          const response = await fetch(
          `${config.apiUri}/accounts/${idAccount}/documents/preview`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
            body: JSON.stringify({
              url: path,
            }),
          })
          const data = await response.blob()
          url = URL.createObjectURL(data)
        } catch (error) {
          console.error(error)
        }

        return url
      },
      async openTrainingDocuments(item, id) {
        this.selectedTraining = item
        this.selectedTraining.id_document = id
        id === 1 ? this.previewedUrl = await this.previewFile(item.training_module_url) : this
          .previewedUrl = await this.previewFile(item.training_attestation_url)
        this.dialogTrainingDocuments = true
      },
      async fetchRatesScale() {
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Authorization', `Bearer ${getCookie('jwt')}`)
        try {
          const response = await fetch(`${config.apiUri}/scales`, {
            headers: headers

          })

          const data = await response.json()
          this.ratesScale = data


          this.ratesScale.forEach(item => {
            item.power != "default" ? item.power = parseInt(item.power) : item.power = item
              .power
            item.rate = parseFloat(item.rate)
          })
        } catch (error) {
          console.error(error)
        }
      },
      getScale() {
        const rateItem = this.ratesScale.find(item => item.power === this.vehicle.power);

        if (rateItem) {
          return rateItem.rate;
        }

        const defaultRateItem = this.ratesScale.find(item => item.power === 'default');

        return defaultRateItem ? defaultRateItem.rate :
          0.697; 
      },
      async fetchVehicleInformations() {
        const idAccount = this.$store.state.user.id

        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Authorization', `Bearer ${getCookie('jwt')}`)

        try {
          const response = await fetch(`${config.apiUri}/accounts/${idAccount}/vehicle`, {
            headers: headers
          })
          const data = await response.json()

          this.vehicle = data[0]

          this.vehicle.power = parseInt(this.vehicle.power)

        } catch (error) {
          console.error(error)
        }
      },
      recordTab(tab) {
        //add url params to the url
        this.$router.push({
          query: {
            menu: tab,
          },
        })
      },
     
      async fetchAddresses() {
        try {
          const response = await fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(this.selectedAddress)}&limit=5`
          );
          if (response.ok) {
            const data = await response.json();
            this.addressOptions = data.features;
          } else {
            console.error('Erreur lors de la requête HTTP');
          }
        } catch (error) {
          console.error(error);
        }
      },
      verifyCostMonth(month, year)
      {
        let currentIdMonth = parseInt(moment().format("MM"))
        let currentYear = parseInt(moment().format("YYYY"))
        const firstDayOfNextMonth = moment().add(1, "months").startOf("month").format("Do MMMM YYYY")
        if (month === currentIdMonth && parseInt(year) === currentYear) {
          this.disabledSendCost = true
          this.errorMsg = `Vous ne pouvez pas envoyer votre note de frais du mois en cours, merci de retenter le
          ${firstDayOfNextMonth}`
        }
        else
        {
          this.disabledSendCost = false
          this.errorMsg = ""
        }
      },
      openDialog(item) {
        this.verifyCostMonth(item.idMonth, item.year)
        this.selectedCostManager = item
        this.dialogAction = true


      },
      getAmountCostPredictionCurrentMonth() {
        this.costAmountOfTheMonth = 0
        let currentIdMonth = parseInt(moment().format("MM"))
        let currentYear = parseInt(moment().format("YYYY"))
        this.costsOrdered.forEach(item => {
          item.month.forEach(month => {
            if (month.month === currentIdMonth && item.year === currentYear) {
              this.costAmountOfTheMonth += month.price
            }
          })
        })

        this.costsOrderedHistory.forEach(item => {
          item.month.forEach(month => {
            if (month.month === currentIdMonth && item.year === currentYear) {
              this.costAmountOfTheMonth += month.price
            }
          })
        })
      },
      getCostsOrderedHistoryFilter(monthOrdered = "", yearOrdered = "") {
        return this.costsOrderedHistory.filter(cost => {
          const isMonthValid = cost.months.toLowerCase().includes(monthOrdered.toLowerCase())
          const isYearValid = cost.year.toString().toLowerCase().includes(yearOrdered
            .toLowerCase())
          // const isYearValid = cost.months.includes(yearOrdered)
          return isMonthValid && isYearValid
        })
      },
      getCostsOrderedFilter(monthOrdered = "", yearOrdered = "") {
        return this.costsOrdered.filter(cost => {
          const isMonthValid = cost.months.toLowerCase().includes(monthOrdered.toLowerCase())
          const isYearValid = cost.year.toString().toLowerCase().includes(yearOrdered
            .toLowerCase())
          // const isYearValid = cost.months.includes(yearOrdered)
          return isMonthValid && isYearValid
        })
      },
      deleteFile(files, index) {
        files.splice(index, 1)
      },
      openDialogPutCost(item) {

        item.idCostCategory = parseInt(item.id_account_cost_category)
        item.date = moment(item.date_cost).format("YYYY-MM-DD")
        item.idTransport = parseInt(item.id_account_cost_sub_category)
        
        if (item.idCostCategory === 1 && item.address_start !== null && item.address_end !== null &&
          item.kilometers !== null && item.scale !== null) {
          this.newCost.idTransport = 2

          this.addressItemsStart = [item.address_start]
          this.addressItemsEnd = [item.address_end]

          this.selectedAddressStart = item.address_start
          this.selectedAddressEnd = item.address_end

          // this.searchAddressStart = item.address_start
          // this.searchAddressEnd = item.address_end
          }
          
          this.newCost = item
        this.dialogPutCost = true

      },
      getTrainingByYear(items) {
        console.log(items)
        let training = []
        items.forEach(item => {
          if (item.year == this.year) {
            training.push(item)
          }
        })
        return training
      },
      previousYear() {
        this.year = moment(this.year).subtract(1, "years").format("YYYY")
      },
      nextYear() {
        this.year = moment(this.year).add(1, "years").format("YYYY")
      },

      generateMonth() {
        for (let i = 1; i <= 12; i++) {
          this.months.push({
            id: i,
            value: i,
            name: moment(i, "MM").format("MMMM"),
          })
        }
      },
      generateYears() {
        for (let i = 2022; i <= moment().format("YYYY"); i++) {
          this.dates.push(i)
        }
      },
      getCostFilter(years = [], months = [], state = []) {
        if (years.length === 0 && months.length === 0 && state.length === 0) {
          return this.user.costs
        }

        return this.user.costs.filter(cost => {
          const isYearValid = years.length === 0 || years.includes(cost.year)
          const isMonthValid = months.length === 0 || months.includes(cost.month)
          const isStateValid = state.length === 0 || state.includes(cost.state)

          return isYearValid && isMonthValid && isStateValid
        })
      },
      update() {
        console.log("proute de table")

        this.fetchFiles().finally(() => {})
        this.fetchNextBirthday()
        this.fetchCostByUserId()
        this.fetchEvents()
        this.fetchMaintenanceByUserId()
        this.fetchAccountTraining()
        this.fetchAgencyHoliday()
      },
      async fetchAgencyHoliday() {

        this.collaborators = []

        let idAgency = this.$store.state.user.agency_id
        try {
          const response = await fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${getCookie('jwt')}`,
            },
          })
          const data = await response.json()

          data.collaborators.forEach(collaborator => {

            collaborator.holidays.forEach(holiday => {
              // Mettre à jour les propriétés de l'objet holiday
              holiday.id = parseInt(holiday.id);
              holiday.id_account = parseInt(holiday.id_account);
              holiday.start_date = holiday.start_date;
              holiday.end_date = holiday.end_date;
              holiday.type = holiday.type;
              holiday.number_of_days = parseFloat(holiday.number_of_days);
              holiday.state = parseInt(holiday.state);
              holiday.is_half_day = parseInt(holiday.is_half_day);
              holiday.half_day_type = parseInt(holiday.half_day_type);
              holiday.remain_paid_leave = parseInt(holiday.remain_paid_leave)
            })
            this.collaborators.push(collaborator)
          })

        

        } catch (e) {
          console.log(e)
        }
      },
      getFormattedName(lastname, firstname) {
        lastname = lastname.toLowerCase().replace(/ /g, "")
        firstname = firstname.toLowerCase().replace(/ /g, "-")
        return `${lastname}-${firstname}`
      },
      async postTrainingDocument(idTraining) {
        try {
          console.log(this.$store.state)
          const formData = new FormData()
          formData.append("agency", this.$store.state.user.agency_name)
          formData.append("lastname", this.$store.state.user.lastname)
          formData.append("firstname", this.$store.state.user.firstname)
          formData.append("idTraining", idTraining)
          this.documentTraining.type === 1 ? formData.append("endpoint", "trainingModule") : null
          this.documentTraining.type === 2 ? formData.append("endpoint", "trainingAttestation") :
            null
          formData.append("file", this.documentTraining.data)
          const res = await fetch(`${config.apiUri}/upload`, {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
          })
          if (res.ok) {
            this.dialogUploadDocument = false

            this.documentTraining = {
              name: "",
              data: {
                name: null,
                type: null,
              },
              categorie: null,
            }

            this.editedUploadTraining = {
              lastname: "",
              firstname: "",
            }

            this.fetchAccountTraining()

            this.dialogTrainingDocuments = false
          } else {
            alert("Erreur lors de l'upload du PDF")
          }
        } catch (err) {
          console.error(err)
          alert("Erreur lors de l'upload du PDF")
        }
      },

      additionnerHeures(heure1, heure2) {
        const format = "HH:mm:ss"
        const moment1 = moment(heure1, format)
        const moment2 = moment(heure2, format)

        // Calculer la somme des deux moments
        const somme = moment
          .duration(moment1.diff(moment("00:00:00", format)))
          .add(moment.duration(moment2.diff(moment("00:00:00", format))))

        // Calculer les jours, heures, minutes et secondes
        const jours = Math.floor(somme.asDays())
        const heures = somme.hours()
        const minutes = somme.minutes()
        const secondes = somme.seconds()

        // Construire la chaîne de caractères finale
        let resultat = [heures, minutes, secondes].map(unit => String(unit).padStart(2, "0")).join(
          ":")

        // Ajouter les jours au résultat si nécessaire
        if (jours > 0) {
          resultat = jours + " jour(s) " + moment(resultat, "HH:mm").format("HH[h]mm")
        }

        return resultat
      },
      additionnerDureeEtHeure(duree, heure) {
        const format = "HH:mm:ss"
        const regex = /(\d+)\s+jour\(s\)\s+(\d+)h(\d+)/ // Pour matcher "X jour(s) YhZ"

        // Extraire les jours, les heures et les minutes de la durée
        const [, jours, heuresDuree, minutesDuree] = duree.match(regex) || []

        // Convertir en objet moment
        let momentDuree = moment.duration({
          days: parseInt(jours),
          hours: parseInt(heuresDuree),
          minutes: parseInt(minutesDuree),
        })

        // Convertir l'heure en objet moment
        let momentHeure = moment.duration(moment(heure, format).diff(moment("00:00:00", format)))

        // Additionner les deux durées
        momentDuree.add(momentHeure)

        // Convertir le résultat en jours, heures, minutes
        const totalJours = Math.floor(momentDuree.asDays())
        const totalHeures = momentDuree.hours()
        const totalMinutes = momentDuree.minutes()
        const totalSecondes = momentDuree.seconds()

        // Formatage du résultat
        let resultat = [totalHeures, totalMinutes, totalSecondes].map(unit => String(unit).padStart(
          2, "0")).join(":")

        if (totalJours > 0) {
          resultat = totalJours + " jour(s) " + moment(resultat, "HH:mm").format("HH[h]mm")
        }

        return resultat
      },
      async fetchAccountTraining() {
        this.user.organization = []
        let idAccount = this.$store.state.user.id
        try {
          const response = await fetch(`${config.apiUri}/accounts/${idAccount}/trainings`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${getCookie('jwt')}`,
            }
          })
          const data = await response.json()
          this.user.organization = data.organization
          let allTrainings = []

          this.user.organization.forEach(organization => {
            let resultDDA = "00:00:00"
            let resultNDDA = "00:00:00"
            let resultByYear = "00:00:00"

            organization.training.forEach(training => {
              training.year = organization.year
              training.display_date = moment(training.date).format("Do MMM YYYY")
              training.duration = moment(training.duration, "HH:mm:ss").format("HH:mm")
              training.display_duration = moment(training.duration, "HH:mm:ss").format(
                "HH[h]mm")

              if (parseInt(training.isDDA) === 1) {
                resultDDA.includes("jour(s)") ?
                  (resultDDA = this.additionnerDureeEtHeure(resultDDA, training
                    .duration)) :
                  (resultDDA = this.additionnerHeures(resultDDA, training.duration))

                resultByYear = this.additionnerHeures(resultByYear, training.duration)

              } else {
                resultNDDA = this.additionnerHeures(resultNDDA, training.duration)
              }
              allTrainings.push(training)
            })

            this.user.allTrainings = allTrainings

            resultDDA.includes("jour(s)") ?
              (organization.sum_DDA_hours = resultDDA) :
              (organization.sum_DDA_hours = moment(resultDDA, "HH:mm:ss").format("HH[h]mm"))
            resultDDA.includes("jour(s)") ?
              (organization.sum_hours = this.additionnerDureeEtHeure(resultDDA, resultNDDA)) :
              (organization.sum_hours = moment(this.additionnerHeures(resultDDA, resultNDDA),
                "HH:mm:ss").format(
                "HH[h]mm",
              ))

          })


        } catch (err) {
          console.log(err)
        }

        console.log(this.user)
      },
      async putMaintenance() {
        const idAccount = localStorage.getItem("user_id")
        try {
          const formData = new FormData()
          formData.append("idAccount", idAccount)
          formData.append("maintenance", JSON.stringify(this.user.maintenance))
          formData.append("place", "putMaintenance")
          fetch(`${config.apiUri}/accounts/${idAccount}`, {
            method: "POST",
            body: formData,
          }).then(response => {
            if (response.ok) {
              this.$toast.success("Vous avez sauvegardé les questions de l'entretien", {
                timeout: 2000,
                position: "bottom-right",
              })
            } else {
              this.$toast.error("Erreur lors de la sauvegarde des questions de l'entretien")
            }
          })
        } catch (err) {
          console.log(err)
        }
      },
      async deleteEvent(id) {
        await fetch(
            `${config.apiUri}/agencies/${this.$store.state.user.agency_id}/events/${id}`, {
              method: "DELETE",
            })
          .then(response => response.json())
          .finally(() => {
            this.dialogAddEvent = false
            this.newEvent = {
              title: "",
              description: "",
              date: "",
              zip: "",
              city: "",
              address: "",
              idCategory: "",
            }
            this.fetchEvents()
          })
          .catch(error => {
            console.error("Error:", error)
          })
      },
      // async getImg(lastname, firstname) {
      //   try {
      //     const name = `${lastname.toLowerCase().replace(" ", "")}-${firstname.toLowerCase()}`
      //     const agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
      //     const pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`
      //     const pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

      //     // Create a Promise to handle image loading
      //     const loadImage = src => {
      //       return new Promise((resolve, reject) => {
      //         const img = new Image()
      //         img.onload = () => resolve(img.src)
      //         img.onerror = () => reject(null) // Failed to load image
      //         img.src = src
      //       })
      //     }

      //     // Attempt to load the PNG image
      //     let result = await loadImage(pictureProfilPng)

      //     // If the PNG image fails to load, try the JPG image
      //     if (!result) {
      //       result = await loadImage(pictureProfilJpg)
      //     }

      //     return result // Return the URL or null if no image is found
      //   } catch (error) {
      //     console.error("Error fetching avatar:", error)
      //     return null // Return null in case of any errors
      //   }
      // },
      async postEvent() {
        const formData = new FormData()
        let idAgency = this.$store.state.user.agency_id
        formData.append("idAgency", idAgency)
        formData.append("idEventCategory", this.newEvent.idCategory)
        formData.append("title", this.newEvent.title)
        formData.append("description", this.newEvent.description)
        formData.append("dateStart", this.newEvent.dateStart)
        if (!this.newEvent.isRecursive) {
          formData.append("dateEnd", this.newEvent.dateEnd)
        }


        // !this.newEvent.isRecursive ? formData.append("dateEnd", this.newEvent.dateEnd) : null
        formData.append("timeStart", this.newEvent.timeStart)
        formData.append("timeEnd", this.newEvent.timeEnd)
        this.newEvent.idCategory != 1 ? formData.append("zip", this.newEvent.zip) : null
        this.newEvent.idCategory != 1 ? formData.append("city", this.newEvent.city) : null
        this.newEvent.idCategory != 1 ? formData.append("street", this.newEvent.street) : null
        this.newEvent.idCategory === 1 ? formData.append("link", this.newEvent.link) : null

        formData.append("each", this.newEvent.isRecursive)

        await fetch(`${config.apiUri}/agencies/${idAgency}/events`, {
            method: "POST",
            body: formData,
          })
          .then(response => response.json())
          .finally(() => {
            this.dialogAddEvent = false
            this.newEvent = {
              title: "",
              description: "",
              date: "",
              zip: "",
              city: "",
              address: "",
              idCategory: "",
            }
            this.fetchEvents()
          })
          .catch(error => {
            console.error("Error:", error)
          })
      },
      selectTab(tab) {
        this.tabProcess = tab
      },
      async deleteCost(id) {
        let idAccount =  this.$store.state.user.id
        await fetch(`${config.apiUri}/accounts/${idAccount}/costs/${id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("jwt")} `,
            },
          })
          .then(response => response.json())
          .finally(() => {
            this.dialogAction = false
            this.fetchCostByUserId()
          })
          .catch(error => {
            console.error("Error:", error)
          })
      },
      sendCost(idCostManager) {

        try {
          fetch(`${config.apiUri}/cost-managers/${idCostManager}/send`, {
              method: "POST",
              body: JSON.stringify({
                idAccount: this.$store.state.user.id,
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("jwt")} `,
              },
            })
            .then(response => {


              if (response.ok) {
                this.$toast.success("La note de frais a été envoyée", {
                  timeout: 2000,
                  position: "bottom-right",
                })
              } else {
                this.$toast.error("Erreur lors de l'envoi de la note de frais")
              }

              this.dialogAction = false
              this.fetchCostByUserId()
            })
            .finally(() => {

            })
            .catch(error => {
              console.error("Error:", error)
            })
        } catch (e) {
          console.log(e)
        }

        this.upload()
      },
      putCost(cost) {
        let id = cost.id

        cost.state = 0

        parseInt(this.newCost.idCostCategory) === 1 && parseInt(this.newCost.idTransport) === 2 ?
          this.newCost.price =
          this.result : null

        const formData = new FormData()
        formData.append("id", id)
        formData.append("idCostManager", this.newCost.id_account_cost_manager)
        formData.append("idCostCategory", this.newCost.idCostCategory)
        formData.append("price", this.newCost.price)
        formData.append("documents", JSON.stringify(this.newCost.documents))
        formData.append("date", this.newCost.date)



        if (parseInt(this.newCost.idCostCategory) === 1 && parseInt(this.newCost.idTransport) ===
          2) {
          formData.append("addressStart", this.selectedAddressStart)
          formData.append("addressEnd", this.selectedAddressEnd)
          formData.append("kilometers", this.newCost.kilometers)
          formData.append("scale", this.getScale(this.vehicle.power))
          formData.append("reason", this.getReasonById(this.newCost.reason))
          this.newCost.reason === 1 || this.newCost.reason === 2 || this.newCost.reason === 3 ?
            formData.append("client", this.newCost.clientName) : null
        }

        formData.append("place", "putCost")

        try {
          fetch(`${config.apiUri}/php/pages/account_costs.php`, {
              method: "POST",
              body: formData,
            })
            .then(response => {
              if (response.ok) {
                this.$toast.success("Vous avez sauvegardé la note de frais", {
                  timeout: 2000,
                  position: "bottom-right",
                })
              } else {
                this.$toast.error("Erreur lors de la sauvegarde de la note de frais")
              }

              this.dialogPutCost = false
              this.stepperPutCost = 1
              this.newCost = {
                designation: "",
                price: "",
                idCostCategory: "",
                documents: [],
                reason: "",
                clientName: "",
                reason: 1,
              }
              this.fetchCostByUserId()
            })
            .finally(() => {

            })
            .catch(error => {
              console.error("Error:", error)
            })
        } catch (e) {
          console.log(e)
        }

        this.upload()
      },
      getReasonByDesignation(designation) {
        let reason = this.reasons.find(reason => reason.designation === designation)
        return reason.id
      },
      getReasonById(id) {
        let reason = this.reasons.find(reason => reason.id === id)
        return reason.designation
      },
      async postCost() {
        this.upload().then(() => {
          let documentsPayload = this.uploadedDocumentUrls

          console.log(documentsPayload)

          const idAccount = this.$store.state.user.id

          parseInt(this.newCost.idCostCategory) === 1 && parseInt(this.newCost
            .idTransport) === 3 ?
            this.newCost.price = this.result : null

          let postData = {
            idAccount: idAccount,
            idCostCategory: this.newCost.idCostCategory,
            idCostSubCategory: null,
            price: this.newCost.price,
            documents: documentsPayload,
            dateCost: this.newCost.date,

          }

          if(!!this.newCost.idTransport)
          {
            postData.idCostSubCategory = this.newCost.idTransport
          }

          if (parseInt(this.newCost.idCostCategory) === 1 && parseInt(this.newCost
              .idTransport) ===
            3) {

            postData.addressStart = this.selectedAddressStart
            postData.addressEnd = this.selectedAddressEnd
            postData.kilometers = this.newCost.kilometers
            postData.scale = this.getScale(this.vehicle.power)
            postData.reason = this.getReasonById(this.newCost.reason)

            this.newCost.reason === 1 || this.newCost.reason === 2 || this.newCost.reason ===
              3 ?
              postData.client = this.newCost.clientName : null
          }

          try {
            fetch(`${config.apiUri}/accounts/${idAccount}/costs`, {
                method: "POST",
                body: JSON.stringify(postData),
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getCookie("jwt")} `,
                },
              })
              .then(response => response.json())
              .finally(() => {
                this.dialogAddCost = false
                this.stepperAddCost = 1
                this.newCost = {
                  designation: "",
                  price: "",
                  idCostCategory: "",
                  documents: [],
                  reason: 1,
                  clientName: "",
                }
                this.fetchCostByUserId()

                this.dialogHRInformations = true
              })
              .catch(error => {
                console.error("Error:", error)
              })
          } catch (e) {
            console.log(e)
          }
        })

      },
      async upload() {
        this.uploadedDocumentUrls = [];

        for (const document of this.newCost.documents) {
          const formData = new FormData();
          formData.append('id', this.$store.state.user.id);
          formData.append('lastname', this.$store.state.user.lastname);
          formData.append('firstname', this.$store.state.user.firstname);
          formData.append('agency', this.$store.state.user.agency_name);
          formData.append('dateCost', this.newCost.date);
          formData.append('endpoint', 'cost');

          formData.append('file', document.data);

          try {
            const response = await fetch(`${config.apiUri}/upload`, {
              method: "POST",
              body: formData,
              headers: {
                "Authorization": `Bearer ${getCookie('jwt')} `,
              },
            });
            if (!response.ok) throw new Error('Erreur lors de la réponse du serveur');

            const responseData = await response.json();
            if (responseData.fileUrl) {
              this.uploadedDocumentUrls.push({
                url: responseData.fileUrl,
                path: responseData.filePath,
              });
            }

            console.log("Document uploadé avec succès:", document.name);
          } catch (err) {
            console.error("Erreur lors de l'upload des documents:", err);
            alert("Erreur lors de l'upload des documents: " + document.name);
            break;
          }
        }
      },
      formatBytes(bytes, decimals = 2) {
        if (!+bytes) return "0 Bytes"

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
      },
      openFileExplorer() {
        const now = new Date()
        // const agencyName = localStorage.getItem("agency").toLowerCase()
        // const lastname = localStorage.getItem("lastname").toLowerCase().replace(" ", "")
        // const firstname = localStorage.getItem("firstname").toLowerCase().replace(" ", "-")
        const input = document.createElement("input")

        this.newCost.datetimeUpload = moment(now).format("YYYY-MM")

        input.type = "file"
        // only word docx pdf
        input.accept = ".pdf, image/*"
        input.multiple = true
        input.addEventListener("change", event => {
          this.document = {
            name: event.target.files[0].name,
            data: event.target.files[0],
          }

          this.newCost.documents.push({
            data: this.document.data,
            // url: `${config.ged}/${agencyName}/users/${lastname}-${firstname}/costs/${this.newCost.datetimeUpload}/${this.document.data.name}`,
            name: this.document.data.name,
            type: this.document.data.type,
            size: this.formatBytes(this.document.data.size),
            date: new Date().toLocaleDateString(),
          })

          this.filesUrl.push(URL.createObjectURL(this.document.data))
        })
        input.click()
      },

      // async fetchMaintenanceByUserId() {
      //   this.user.maintenance = []
      //   this.avatarUrl = await this.getImg(this.user.lastname, this.user.firstname)
      //   let idUser = localStorage.getItem("user_id")
      //   try {
      //     const response = await fetch(`${config.apiUri}/accounts/${idUser}/maintenances`, {
      //       method: "GET",
      //     })
      //     const data = await response.json()

      //     if (data.code === 1) {

      //       this.user.maintenance = JSON.parse(data.maintenance)
      //       this.user.services = data.services
      //       this.user.manager = data.services[0].manager[0]

      //     }

      //   } catch (e) {
      //     console.log(e)
      //   }
      // },
      async fetchCostByUserId() {
        this.costsOrdered = []
        this.costsCategory = []
        this.user.costs = []
        let idUser = this.$store.state.user.id
        try {
          const response = await fetch(`${config.apiUri}/accounts/${idUser}/costs`, {
            method: "GET",
            headers: {
              "Authorization": `Bearer ${getCookie('jwt')}`,
            },
          })
          const data = await response.json()

          this.service = data.service[0]

          data.costs.forEach(yearObj => {
            yearObj.months.forEach(monthObj => {
              let totalMonth = 0
              monthObj.costs.forEach(item => {
                // Traitement de chaque item de coût
                item.id = item.id
                item.designation = item.designation_category
                item.price = parseFloat(item.price)
                item.documents = JSON.parse(item.documents)
                item.id_account = item.id_account
                item.id_account_cost_category = item.id_account_cost_category
                item.data = item.data != null ? JSON.parse(item.data) : null
                item.date = item.date
                item.date_display = moment(item.date).format("DD/MM/YYYY")
                item.date_cost = item.date_cost
                item.date_cost_display = moment(item.date_cost).format("DD/MM/YYYY")
                item.month = parseInt(moment(item.date_cost).format("MM"))
                item.month_display = moment(item.date_cost).format("MMMM")
                item.year = parseInt(moment(item.date_cost).format("YYYY"))
                item.state = parseInt(item.cost_state)
                item.manager_comment = item.manager_comment
                item.address_start = item.address_start
                item.address_end = item.address_end
                item.kilometers = item.kilometers
                item.scale = item.scale
                item.clientName = item.client
                item.reason = item.reason != null ? this.getReasonByDesignation(item
                  .reason) : item.reason
                totalMonth += parseFloat(item.price)
              })
              // Ajout des informations de l'utilisateur et du mois dans costsOrdered

              if (parseInt(monthObj.state) === 2 || parseInt(monthObj.state) === 1 ||
                parseInt(monthObj.state) === 0) {
                this.costsOrdered.push({
                  id_cost_manager: parseInt(monthObj.id_cost_manager),
                  manager_comment: monthObj.manager_comment,
                  year: yearObj.year,
                  idMonth: parseInt(
                    moment(monthObj.costs.length > 0 ? monthObj.costs[0].date_cost :
                      "").format("MM"),
                  ),
                  month: monthObj.costs,

                  monthPrice: parseFloat(totalMonth).toFixed(2),
                  state: parseInt(monthObj.state),
                  months: moment(monthObj.costs.length > 0 ? monthObj.costs[0]
                    .date_cost : "").format("MMMM"),
                })
              } else {
                this.costsOrderedHistory.push({
                  id_cost_manager: parseInt(monthObj.id_cost_manager),
                  manager_comment: monthObj.manager_comment,

                  year: yearObj.year,
                  idMonth: parseInt(
                    moment(monthObj.costs.length > 0 ? monthObj.costs[0].date_cost :
                      "").format("MM"),
                  ),
                  month: monthObj.costs,

                  monthPrice: totalMonth,
                  state: parseInt(monthObj.state),
                  months: moment(monthObj.costs.length > 0 ? monthObj.costs[0]
                    .date_cost : "").format("MMMM"),
                })
              }
            })
          })

          this.costsCategory = data.costs_categories

          this.costsCategory.forEach(category => {
            category.id = parseInt(category.id)
            category.designation = category.designation
          })

          this.getAmountCostPredictionCurrentMonth()
          console.log("Oerdred 7", this.costsOrdered)
        } catch (e) {
          console.log(e)
        }
      },
      flashTab() {
        if (!this.isTabFlashing) {
          const originalTitle = document.title
          let flash = true

          const flashInterval = setInterval(() => {
            flash = !flash
            document.title = flash ? "Nouveau message reçu" : originalTitle
          }, 1000) // Change le titre toutes les 1000 ms (1 seconde)

          // Arrête de clignoter après quelques secondes
          setTimeout(() => {
            clearInterval(flashInterval)
            document.title = originalTitle
            this.isTabFlashing = false
          }, 5000) // Arrête de clignoter après 5 secondes

          this.isTabFlashing = true

          window.focus() // Focus sur la fenêtre du navigateur
        }
      },
      joinRoom(roomName) {
        // Émettre un événement pour rejoindre la salle sélectionnée
        const userName = localStorage.getItem("complete_name")
        this.socket.emit("changeRoom", userName, roomName)
        this.selectedRoom = roomName

        // put in this room push user
        this.rooms.forEach(room => {
          if (room.name === roomName) {
            room.users.push(userName)
          }
        })

        // Vous pouvez également mettre à jour l'interface utilisateur ici pour indiquer la salle actuelle
      },
      formatMessageText(text) {
        // Utilisez une expression régulière pour détecter les liens dans le texte
        const urlRegex = /(https?:\/\/[^\s]+)/g
        const formattedText = text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>')
        return formattedText
      },
      isReceivedMessage(message) {
        return message.senderId !== localStorage.getItem("complete_name")
      },
      scrollToBottom() {
        this.$nextTick(() => {
          const messagesContainer = this.$refs.messagesContainer
          messagesContainer.scrollTop = messagesContainer.scrollHeight
        })
      },
      sendMessage() {
        let userName = localStorage.getItem("complete_name")
        let lastname = localStorage.getItem("lastname").toLowerCase()
        let firstname = localStorage.getItem("firstname").toLowerCase()
        let agency = localStorage.getItem("agency").toLowerCase()
        if (this.newMessage) {
          const randomId = Math.floor(Math.random() * 1000000)
          const senderId = localStorage.getItem("complete_name")
          const text = this.newMessage
          let datetime = new Date().toISOString()
          this.socket.emit("message", {
            id: randomId,
            senderId,
            text,
            datetime,
            roomName: this.selectedRoom,
            avatar: `${config.ged}/${agency}/users/${lastname}-${firstname}/profil.png`,
          })
          this.socket.emit("stopTyping", this
            .userName) // Envoyez un événement pour arrêter "en train d'écrire".
          ;
          (datetime = moment(datetime).calendar()),
          this.messages.push({
            id: randomId,
            senderId,
            text,
            datetime,
            avatar: `${config.ged}/${agency}/users/${lastname}-${firstname}/profil.png`,
          })
          this.newMessage = ""
        }
        this.scrollToBottom()
      },
      deleteMessage(id) {
        this.messages.splice(
          this.messages.findIndex(message => message.id === id),
          1,
        )
      },
      startTyping() {
        const userName = localStorage.getItem("complete_name")
        this.socket.emit("userTyping", userName)
      },
      stopTyping() {
        const userName = localStorage.getItem("complete_name")
        this.isTyping = false
        this.socket.emit("stopTyping", userName)
      },
      async fetchEvents() {

        this.events = []
        let headers = new Headers()
        let agencyId = this.$store.state.user.agency_id

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        try {
          let response = await fetch(`${config.apiUri}/agencies/${agencyId}/events`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
          const data = await response.json()

          if (response.ok) {
            data.category.forEach(category => {
              this.eventsCategory.push({
                id: category.id,
                designation: category.designation,
              })
            })
            data.events.forEach(element => {
              let information = ""
              // verify if element.time_start and time_end is between current hour

              if (parseInt(element.each_time) === 1) {
                // get day like "monday"
                information = moment(element.date_start).format("dddd")
              }

              let currentTime = moment()
              let timeStart = moment(element.time_start, "HH:mm:ss").format("HH:mm")
              let timeEnd = moment(element.time_end, "HH:mm:ss").format("HH:mm")
              let isNow = false

              if (currentTime.isBetween(timeStart, timeEnd)) {
                isNow = true
              }

              this.events.push({
                id: element.id,
                label: element.label,
                description: element.description,
                each: parseInt(element.each_time),
                information: information,
                date_start: element.date_start,
                date_end: element.date_end,
                street: element.street,
                city: element.city,
                zip: element.zip,
                time_start: timeStart,
                time_end: timeEnd,
                id_agency_event_category: parseInt(element.id_agency_event_category),
                link: element.link,
                isNow: isNow,
              })
            })
          }
        } catch (e) {
          console.log(e)
        }
      },

      async fetchNextBirthday() {
        this.nextBirthday = []
        let headers = new Headers()
        let agencyId = this.$store.state.user.agency_id

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        try {
          let response = await fetch(`${config.apiUri}/agencies/${agencyId}/birthdate`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
          const data = await response.json()

          if (response.ok) {

            data.forEach(element => {
              this.nextBirthday.push({
                lastname: element.lastname,
                firstname: element.firstname,
                date: element.birth_date,
                time: moment(element.next_birthday).endOf("days").fromNow(),
                nextAge: moment().diff(element.birth_date, "years") + 1,
                dateFormatted: moment(element.next_birthday).format("Do MMMM YYYY"),

                // diffe between today and birthdate
              })
            })
          }

        } catch (e) {
          console.log(e)
        }
      },
      async fetchFiles() {
        let agencyId = this.$store.state.user.agency_id
        try {
          let response = await fetch(`${config.apiUri}/agencies/${agencyId}`, {
            mode: "cors",
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${getCookie('jwt')}`,
            },
          })
          const data = await response.json()

          const agency = data.agency[0]
          console.log("DATA ILFES", data)
          
          if (response.ok) {
            agency.files.forEach(category => {
              switch (parseInt(category.id)) {
                case 5:
                  category.files.forEach(item => {

                    if (item.url != null && item.path != null) {

                      this.files.display.push(item)
                    }
                  })

                  break
                case 9:
                  category.files.forEach(item => {

                    if (item.url != null && item.path != null) {

                      this.files.money_accord.push(item)
                    }
                  })

                  break
                case 11:
                  category.files.forEach(item => {
                    this.files.complaint.push(item)
                  })
                  break
                case 12:
                  category.files.forEach(item => {
                    this.files.lcbft.push(item)
                  })
                  break
                case 13:
                  category.files.forEach(item => {
                    this.files.ppe.push(item)
                  })
                  break
                case 14:
                  category.files.forEach(item => {
                    this.files.dda.push(item)
                  })
                  break
                case 15:
                  category.files.forEach(item => {
                    this.files.rgpd.push(item)
                  })
                  break
                case 16:
                  category.files.forEach(item => {
                    this.files.gda.push(item)
                  })
                  break
              }
            })
          }
          console.log(this.files)
          // this.files.forEach(element => {
          //   element.upload_datetime_format = moment(element.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
          // })
        } catch (e) {
          console.log(e)
        }
      },

      // sortFiles() {
      //   this.files.forEach(file => {
      //     switch (parseInt(file.id_file_categorie)) {
      //       case 5:
      //         let item = []
      //         item.push(file)
      //         file.push(item)

      //         break
      //     }
      //     console.log(file)
      //   })
      // },
    },
  }

</script>

<style>
  .no-padding-bottom {

    padding-bottom: 0 !important;
  }

  .no-padding {
    padding: 0 !important;
  }

  .col-files {
    max-width: 20%;
  }

  .message {
    margin-bottom: 10px;
  }

  .message-left {
    text-align: left;
  }

  .message-right {
    text-align: right;
  }

  .message-sender {
    /* font-weight: bold; */
  }

  .message-text {
    background-color: #f0f0f0;
    padding: 5px;
    border-radius: 5px;
  }

  .message-text a {
    color: #2196f3;
    text-decoration: underline;
  }

  .typing-message {
    margin-bottom: 10px;
    color: gray;
    font-size: small;
  }

  .messages-container {
    height: 500px;
    overflow-y: scroll;
  }

  .channel-container {
    margin-bottom: 20px;
  }

</style>
